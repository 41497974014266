import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AccountService, SubscriptionType } from 'src/app/services/account.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { LanguageService } from 'src/app/services/language.service';
import { SettingsService } from 'src/app/services/settings.service';
import { SubscriptionSuccessDialogComponent } from './subscription-success-dialog/subscription-success-dialog.component';
import { SubscriptionDeleteDialogComponent } from './subscription-delete-dialog/subscription-delete-dialog.component';
import { WebviewService } from 'src/app/services/webview.service';


const MONTHLY_COST_MONTHLY_PLAN_PRO = 4.95;
const MONTHLY_COST_MONTHLY_PLAN_PROPLUS = 7.95;

/**
   * adding, managing user subscriptions
   */
@Component({
  selector: 'app-subscriptions-dialog',
  templateUrl: './subscriptions-dialog.component.html',
  styleUrls: ['./subscriptions-dialog.component.scss']
})
export class SubscriptionsDialogComponent implements OnInit {

  public userSubscription: SubscriptionType;

  public subscriptionFeatures: {
    shortDescription: string, free: boolean, pro: boolean, proplus: boolean, longDescription: string, screenshot1: string, screenshot2: string,
    dropdown: boolean
  }[] = [
      {
        shortDescription: "Turn-by-turn navigation", free: true, pro: true, proplus: true,
        longDescription: "Stay on the right path with our intuitive Turn-by-Turn Navigation feature. Receive clear, real-time directions at every intersection, ensuring a smooth and stress-free driving experience. Whether you're navigating busy city streets or scenic country roads, our precise guidance helps you stay focused on the journey ahead.",
        screenshot1: "", screenshot2: "", dropdown: false
      },
      {
        shortDescription: "Worldwide charging station database", free: true, pro: true, proplus: true,
        longDescription: "Access a comprehensive worldwide charging station database, ensuring you can find reliable charging points wherever your journey takes you. From major cities to remote locations, our database keeps you connected to the nearest charging stations for a hassle-free EV experience.",
        screenshot1: "", screenshot2: "", dropdown: false
      },
      {
        shortDescription: "Route planning > 500km", free: false, pro: true, proplus: true,
        longDescription: "Plan journeys over 500 km with advanced route optimization and seamless charging station integration for uninterrupted long-distance travel.",
        screenshot1: "", screenshot2: "", dropdown: false
      },

      {
        shortDescription: "Voice input", free: false, pro: true, proplus: true,
        longDescription: "Enhance your driving experience with Voice Input, allowing you to control your navigation hands-free. Simply speak your destination or commands, and our software will take care of the rest, providing a safe and convenient way to navigate while keeping your focus on the road.",
        screenshot1: "", screenshot2: "", dropdown: false
      },
      {
        shortDescription: "Android Auto / CarPlay", free: false, pro: true, proplus: true,
        longDescription: "Seamlessly connect with Android Auto and Apple CarPlay for an enhanced in-car experience. Access your navigation, music, and other essential apps directly from your vehicle's display, ensuring a smooth and integrated journey without distractions.",
        screenshot1: "", screenshot2: "", dropdown: false
      },
      {
        shortDescription: "Weather forecast", free: false, pro: true, proplus: true,
        longDescription: "Plan your trips with confidence using integrated Weather Forecasts. Get real-time weather updates along your route, helping you anticipate conditions and adjust your journey for a safer, more efficient drive.",
        screenshot1: "", screenshot2: "", dropdown: false
      },
      {
        shortDescription: "Live charging station status", free: false, pro: false, proplus: true,
        longDescription: "Stay informed with Live Charging Station Status, providing real-time updates on availability, charging speeds, and occupancy. No more guesswork—find the perfect station for your EV with up-to-the-minute information to keep your journey running smoothly.",
        screenshot1: "", screenshot2: "", dropdown: false
      },
      {
        shortDescription: "Live traffic", free: false, pro: false, proplus: true,
        longDescription: "Navigate efficiently with Live Traffic updates, providing real-time data on road conditions, congestion, and accidents. Avoid delays and choose the fastest routes with dynamic, traffic-aware guidance to ensure a smoother drive.",
        screenshot1: "", screenshot2: "", dropdown: false
      }
    ];

  public subscriptionPlans: {
    type: string, monthlyCostMonthlyPlan: number, monthlyCostYearlyPlan: number, yearlyCostMonthlyPlan: number, yearlyCostYearlyPlan: number,
    period: string
  }[] = []

  public packageList = ["free", "pro", "proplus"];
  private subscriptionOrder = [
    SubscriptionType.Free,
    SubscriptionType.ProMonthly,
    SubscriptionType.ProYearly,
    SubscriptionType.ProplusMonthly,
    SubscriptionType.ProplusYearly
  ];

  constructor(public dialogRef: MatDialogRef<SubscriptionsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialog: MatDialog, public languageService: LanguageService, private fb: FormBuilder,
    public settingsService: SettingsService, public accountService: AccountService, private consoleLoggerService: ConsoleLoggerService,
    private webviewService: WebviewService) {
  }

  ngOnInit(): void {
    this.userSubscription = this.accountService.getUserSubscription();

    this.subscriptionPlans = [
      {
        type: "pro",
        monthlyCostMonthlyPlan: MONTHLY_COST_MONTHLY_PLAN_PRO,
        monthlyCostYearlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PRO * 10 / 12 * 100) / 100,
        yearlyCostMonthlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PRO * 12 * 100) / 100,
        yearlyCostYearlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PRO * 10 * 100) / 100,
        period: "yearly"
      },
      {
        type: "proplus",
        monthlyCostMonthlyPlan: MONTHLY_COST_MONTHLY_PLAN_PROPLUS,
        monthlyCostYearlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PROPLUS * 10 / 12 * 100) / 100,
        yearlyCostMonthlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PROPLUS * 12 * 100) / 100,
        yearlyCostYearlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PROPLUS * 10 * 100) / 100,
        period: "yearly"
      }
    ];
  }

  public subscribeToSubscription(plan) {
    if (this.webviewService.IsGPSTWebview()) {
      this.webviewService.StartSubscription(plan);
    }
    else {
      this.accountService.setUserSubscription(`${plan.type}.${plan.period}` as SubscriptionType);

      this.matDialog.open(SubscriptionSuccessDialogComponent,
        {
          data: { subscriptionType: `${plan.type}.${plan.period}` as SubscriptionType },
          autoFocus: false
        });

      this.dialogRef.close();
    }
  }

  public compareSubscriptions(sub1: SubscriptionType, sub2: string): number {
    const index1 = this.subscriptionOrder.indexOf(sub1);
    const index2 = this.subscriptionOrder.indexOf(sub2 as SubscriptionType);

    return (index1 >= index2) ? 1 : 0;
  }

  public cancelSubscription() {
    const dialog = this.matDialog.open(SubscriptionDeleteDialogComponent,
      {
        data: { subscriptionType: this.userSubscription },
        autoFocus: false
      });

    dialog.afterClosed().subscribe(() => {
      this.userSubscription = this.accountService.getUserSubscription();
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public finish(): void {
    this.dialogRef.close();
  }
}
