import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { LanguageService } from 'src/app/services/language.service';
import { AccountDialogComponent } from '../../account-dialog/account-dialog.component';
import { StackedModalsService } from 'src/app/services/stacked-modals.service';
import { SubscriptionsDialogComponent } from '../subscriptions-dialog.component';

@Component({
  selector: 'app-subscription-required-dialog',
  templateUrl: './subscription-required-dialog.component.html',
  styleUrl: './subscription-required-dialog.component.scss'
})
export class SubscriptionRequiredDialogComponent {

  public feature: string;

  constructor(public dialogRef: MatDialogRef<SubscriptionRequiredDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService, public accountService: AccountService, private stackedModalsService: StackedModalsService) {
    
      
    if (this.data.feature == "route-planning") {
      this.feature = "Plan journeys over 500km";
    }
  }

  public register(): void {
    this.stackedModalsService.openModal(AccountDialogComponent, {
      data: { process: "register" },
      panelClass: 'dialog-snap-to-fullscreen', autoFocus: false
    });
    this.dialogRef.close();
  }

  public activate(): void {
    this.stackedModalsService.openModal(SubscriptionsDialogComponent, {
      data: { process: 'signIn' },
      panelClass: 'dialog-snap-to-fullscreen', autoFocus: false
    });
    this.dialogRef.close();
  }

  public login(): void {
    this.stackedModalsService.openModal(AccountDialogComponent, {
      data: { process: 'signIn' },
      panelClass: 'dialog-snap-to-fullscreen', autoFocus: false
    });
    this.dialogRef.close();
  }

  public close(): void {
    this.dialogRef.close();
  }
}
