import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import 'leaflet';
import * as L from 'leaflet';
import { skip } from 'rxjs/operators';
import { AccountService, SubscriptionType } from 'src/app/services/account.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { InputParamsService } from 'src/app/services/input-params.service';
import { LanguageService } from 'src/app/services/language.service';
import { MapService } from 'src/app/services/map.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { SettingsService } from 'src/app/services/settings.service';
import { StackedModalsService } from 'src/app/services/stacked-modals.service';
import { environment } from 'src/environments/environment';
import { AccountDialogComponent } from '../modals/account-dialog/account-dialog.component';
import { SettingsDialogComponent } from '../modals/settings-dialog/settings-dialog.component';
import { TabbedInfoDialogComponent } from '../modals/tabbed-info-dialog/tabbed-info-dialog.component';
import device from 'current-device';
import { HistoryDialogComponent } from '../modals/history-dialog/history-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../modals/confirmation-dialog/confirmation-dialog.component';
import { Subscription } from 'rxjs';
import { SearchedRouteElement } from '../../models/searched-route-element';
import { SubscriptionsPromoDialogComponent } from '../modals/subscriptions-promo-dialog/subscriptions-promo-dialog.component';
import { SubscriptionsDialogComponent } from '../modals/subscriptions-dialog/subscriptions-dialog.component';

@Component({
  selector: 'app-mobile-main-menu',
  templateUrl: './mobile-main-menu.component.html',
  styleUrls: ['./mobile-main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileMainMenuComponent implements OnInit {

  @Input() noRoute: boolean;
  @Input() failedPlan: boolean;
  @Input() loadingMap: string;
  private subscriptions: Subscription[] = [];
  public userMonogram: string = "";
  public dropdown: string = "";
  public isAuthorized: boolean;
  public searched: boolean;
  public rhaSearchValue: string = "";
  public rhaSearchActive: boolean = false;
  public apiUrl: string = environment.API_URL;
  public selectedMode: string = "rha";
  public mobileVisiblePanel: string = "";
  public mobileVisibleRoutePanel: string = "routepoints";
  public subscriptionType: SubscriptionType;

  constructor(public settingsService: SettingsService,
    private stackedModalsService: StackedModalsService,
    public accountService: AccountService,
    private inputParamsService: InputParamsService,
    public mobileResolutionService: MobileResolutionService,
    private mapService: MapService,
    private consoleLoggerService: ConsoleLoggerService,
    public languageService: LanguageService,
    private cdr: ChangeDetectorRef,
    public MatDialog: MatDialog) { }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  private initSubscriptions(): void {
    this.subscriptions.push(this.accountService.ObservableIsAuthorized.subscribe((resp) => {
      if (resp != undefined) {
        this.isAuthorized = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableSelectedMode.subscribe((resp) => {
      if (resp == "route") {
        this.searched = false;
      }
      this.selectedMode = resp;
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((resp) => {
      this.mobileVisiblePanel = resp;
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisibleRoutePanel.subscribe((resp) => {
      this.mobileVisibleRoutePanel = resp;
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.settingsService.ObservableMap.pipe(skip(1)).subscribe((mapsettings) => {
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.accountService.ObservableUser.subscribe((resp) => {
      if (resp != null && resp != undefined && resp.Profile.FirstName && resp.Profile.LastName) {
        this.userMonogram = resp.Profile.LastName ? resp.Profile.FirstName[0] + resp.Profile.LastName[0] : resp.Profile.FirstName[0];
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.languageService.ObservableLanguageLoaded.subscribe((resp) => {
      if (resp) {
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.accountService.ObservableSubscriptionType.subscribe((resp)=>{
      if (resp){
        this.subscriptionType = resp;
        this.cdr.detectChanges();
      }
    }));
  }

  public isMobile(): boolean {
    return device.mobile();
  }

  public dropdownClick(selectedDropdown: string) {
    if (this.dropdown == selectedDropdown) {
      this.dropdown = "";
      this.setMobileVisiblePanel("");
    }
    else {
      this.dropdown = selectedDropdown;
      this.setMobileVisiblePanel("menudropdown");
    }
  }

  public openSettings(): void {
    this.stackedModalsService.openModal(SettingsDialogComponent,
      { panelClass: 'dialog-snap-to-fullscreen', disableClose: true, autoFocus: false });
    this.setMobileVisiblePanel("");
  }

  public openFeedbackDialog(): void {
    this.stackedModalsService.openModal(TabbedInfoDialogComponent, {
      panelClass: 'dialog-snap-to-fullscreen',
      autoFocus: false,
      data: { openMenu: "feedback" }
    });
  }

  public openAccountDialog(): void {
    const process = this.accountService.getIsAuthorized() ? "profileEdit" : "signIn";
    this.stackedModalsService.openModal(AccountDialogComponent, {
      data: { process: process },
      panelClass: 'dialog-snap-to-fullscreen', autoFocus: false
    });
  }

  public openHistoryDialog(): void {
    this.stackedModalsService.openModal(HistoryDialogComponent, {
      panelClass: 'dialog-snap-to-fullscreen', autoFocus: false,
    });
  }

  public setMobileVisiblePanel(panelName: string): void {
    this.mobileVisiblePanel = panelName;
    this.mobileResolutionService.setMobileVisiblePanel(panelName);
  }

  public setMobileVisibleRoutePanel(panelName: string): void {
    this.mobileVisibleRoutePanel = panelName;
    this.mobileResolutionService.setMobileVisibleRoutePanel(panelName);
    this.dropdown = "";
    this.setMobileVisiblePanel("");
  }

  // adding searched location to service
  public searchChangedHandler(searchData: SearchedRouteElement): void {
    if (this.mobileResolutionService.getMobileVisiblePanel() == 'search') {
      this.mobileResolutionService.setMobileVisiblePanel('');
    }
    this.mapService.setSearchedLocation(searchData);
    this.searched = true;
    this.cdr.detectChanges();
  }

  public searchActiveChangedHandler(searchActive: boolean): void {
    this.rhaSearchActive = searchActive;
    this.cdr.detectChanges();
  }

  public openSubscriptionsDialog(){
    this.stackedModalsService.openModal(SubscriptionsDialogComponent, {
      autoFocus: false,
      panelClass: 'dialog-snap-to-fullscreen'
    });
  }

  public openSubscriptionsPromo() {
    this.stackedModalsService.openModal(SubscriptionsPromoDialogComponent, {
      autoFocus: false,
      panelClass: 'dialog-snap-to-fullscreen'
    });
  }

  public setSearchedToHome(): void {
    if (!this.isAuthorized) {
      this.openAccountDialog();
    }
    else {
      let homeAddress = this.settingsService.getHomeAddress();
      if (homeAddress?.Description) {
        let homeElement = {
          display_name: homeAddress.Description,
          lat: homeAddress.Location.lat,
          lon: homeAddress.Location.lng,
          boundingbox: homeAddress.BoundingBox
        }
        this.searchChangedHandler(homeElement);
        this.rhaSearchValue = homeElement.display_name;
        this.cdr.detectChanges();
      }
      else {
        this.openSettings();
      }
    }
  }

  public setSearchedToWork(): void {
    if (!this.isAuthorized) {
      this.openAccountDialog();
    }
    else {
      const workAddress = this.settingsService.getWorkAddress();
      if (workAddress?.Description) {
        let workElement = {
          display_name: workAddress.Description,
          lat: workAddress.Location.lat,
          lon: workAddress.Location.lng,
          boundingbox: workAddress.BoundingBox
        }
        this.searchChangedHandler(workElement);
        this.rhaSearchValue = workElement.display_name;
        this.cdr.detectChanges();
      }
      else {
        this.openSettings();
      }
    }
  }

  // set start coord after search
  public setStartCoordsParamToSearchedResult(): void {
    this.searched = false;
    const searchData = this.mapService.getSearchedLocation();
    let routeList = [];
    routeList[0] = searchData;
    routeList[0].wp = 0;
    const latlon = new L.LatLng(searchData.lat, searchData.lon);
    this.inputParamsService.setSearchedRangeOrRoute(true);
    this.inputParamsService.setStartCoordsParams(latlon);
    this.inputParamsService.setWaypointsRouteList(routeList);
  }

  // set end coord after search
  public setEndCoordsParamToSearchedResult(): void {
    this.searched = false;
    const searchData = this.mapService.getSearchedLocation();
    let routeList = this.inputParamsService.getWaypointsRouteList();
    routeList[1] = { display_name: searchData.display_name, lat: searchData.lat, lng: searchData.lon };
    routeList[1].wp = 1;
    let latlon = new L.LatLng(searchData.lat, searchData.lon);
    this.inputParamsService.setSearchedRangeOrRoute(true);
    let waypoints = [...this.inputParamsService.getWaypointsParams()];
    waypoints.push(latlon);
    this.inputParamsService.setWaypointParams(waypoints);
    this.inputParamsService.setWaypointsRouteList(routeList);
  }

  private stopTrackingDialog(): MatDialogRef<ConfirmationDialogComponent> {
    return this.MatDialog.open(ConfirmationDialogComponent, {
      data:
      {
        title: this.languageService.languageJSON.Map_StopTracking,
        message: this.languageService.languageJSON.Map_StopTracking_Message,
        buttons: [this.languageService.languageJSON.Global_Stop, this.languageService.languageJSON.Global_Cancel]
      }, autoFocus: false
    });
  }

  public logout(): void {
    this.accountService.logout();
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
