import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { LanguageService } from 'src/app/services/language.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-subscriptions-promo-dialog',
  templateUrl: './subscriptions-promo-dialog.component.html',
  styleUrls: ['./subscriptions-promo-dialog.component.scss']
})
export class SubscriptionsPromoDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SubscriptionsPromoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService, public accountService: AccountService, public settingsService: SettingsService) {

  }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    localStorage.setItem("subscriptionsPromo", "true");
  }
}
