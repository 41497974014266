import { Injectable } from '@angular/core';
import L, { CircleMarker, DivIcon, Icon, LatLng, Map, Marker, icon } from 'leaflet';
import { MapSkin } from './settings.service';
import { AntPath } from 'leaflet-ant-path';
import { Waypoint } from '../models/range-params';
import { Incident, RoadSurfaceAlert } from '../models/route';

const CHARGER_GROUP_SIZES = [45, 50, 60, 70];
const CHARGER_SIZES = [34, 44, 54, 60];
const CHARGER_GROUP_COLORS = [
  { color: "#b9b753", skin: MapSkin.Dark },
  { color: "#65b17c", skin: MapSkin.Dark },
  { color: "#b165ab", skin: MapSkin.Dark },
  { color: "#9a9707", skin: MapSkin.Light },
  { color: "#218f42", skin: MapSkin.Light },
  { color: "#8F2186", skin: MapSkin.Light }
];

@Injectable({
  providedIn: 'root'
})

export class MapDrawerHelperService {

  private offscreenCanvas;

  public startIcon: Icon = icon({
    iconUrl: "../assets/icons/dark/map/route_point_start.svg",
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });

  public startIconLight: Icon = icon({
    iconUrl: "../assets/icons/light/map/route_point_start.svg",
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });

  public waypointIcon: Icon = icon({
    iconUrl: "../assets/icons/dark/map/route_point_waypoint.svg",
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });

  public endIcon: Icon = icon({
    iconUrl: '../assets/icons/dark/map/route_point_target.svg',
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });

  public endIconLight: Icon = icon({
    iconUrl: '../assets/icons/light/map/route_point_target.svg',
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });

  public endIconFailed: Icon = icon({
    iconUrl: '../assets/icons/dark/map/route_point_target_failed.svg',
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });

  public endIconFailedLight: Icon = icon({
    iconUrl: '../assets/icons/light/map/route_point_target_failed.svg',
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });

  // RHA center marker icon definition
  public rhaCursor: Icon = icon({
    iconAnchor: [16, 16],
    iconUrl: "./assets/icons/dark/map/cursor.png",
    iconSize: [32, 32],
    className: 'rha-cursor'
  });

  constructor() {
    this.initChargerIcons();
  }

  public getMapComponent(startCoords: Waypoint): Map {
    return L.map('leaflet', {
      zoom: 6,
      center: startCoords,
      zoomControl: false,
      zoomAnimationThreshold: 2,
      renderer: L.canvas(),
      attributionControl: false,
      tap: false
    });
  }

  public getPlaceCursorIcon(skin: MapSkin): Icon {
    return icon({
      iconUrl: `../assets/icons/${skin}/map/longtap_placecursor.png?v=2`,
      iconSize: [50, 50],
      iconAnchor: [70, 100],
      className: 'move-in-right'
    });
  }

  public getRouteCursorIcon(skin: MapSkin, selectedMode: string): Icon {
    const iconUrl = selectedMode == "route" ? `../assets/icons/${skin}/map/longtap_addwaypoint.png?v=2` : `../assets/icons/${skin}/map/longtap_routetohere.png?v=2`;

    return icon({
      iconUrl: iconUrl,
      iconSize: [50, 50],
      iconAnchor: [-18, 100],
      className: 'move-in-left'
    });
  }

  public getContextMenuCircle(latlng: LatLng, skin: MapSkin): CircleMarker {
    if (skin === MapSkin.Light) {
      return L.circleMarker(latlng, { radius: 25, color: '#7ed3f3', fillColor: "#fff", fillOpacity: 0.7 });
    }
    else {
      return L.circleMarker(latlng, { radius: 25, color: '#81d4fa' });
    }
  }

  public getRhaCursorDivIcon(skin: MapSkin, bearing: number) {
    let rhaCursorHtml = `<span id="rhamarker-outer"><img class="cursor-marker" alt="cursor" src="assets/icons/${skin}/map/cursor.png"
    style="transform:rotate(${bearing}deg);" /></span>`;
    return L.divIcon({ html: rhaCursorHtml, iconAnchor: [25, 25], iconSize: [50, 50] });
  }

  public getStartMarker(skin: MapSkin, startPos: LatLng): Marker {
    return L.marker(startPos, { icon: skin == MapSkin.Light ? this.startIconLight : this.startIcon });
  }

  public getEndMarker(skin: MapSkin, endPos: LatLng, failedPlan: boolean): Marker {
    if (skin === MapSkin.Light && !failedPlan) {
      return L.marker(endPos, { icon: this.endIconLight });
    }
    else if (skin === MapSkin.Light && failedPlan) {
      return L.marker(endPos, { icon: this.endIconFailedLight });
    }
    else if (skin === MapSkin.Dark && !failedPlan) {
      return L.marker(endPos, { icon: this.endIcon });
    }
    else {
      return L.marker(endPos, { icon: this.endIconFailed });
    }
  }

  public getChargerHtmlElements(skin: MapSkin, statusType: string, statusTypeText: string, statusStateText: string): string {
    return `<img class="status-icon" src="/assets/icons/${skin}/map/status_icon_${statusType}.svg"/>
    <div class="status-state">${statusStateText ? "<p>" + statusStateText + "</p>" : ""}<p class="state-type">${statusTypeText}</p></div>`;
  }

  public getRoutePlanningAntPath(waypoints: LatLng[], skin: MapSkin): AntPath {
    return new AntPath(waypoints, {
      "delay": 300,
      "weight": 6,
      "color": skin === MapSkin.Light ? "#059cdb" : "#81d4fa",
      "pulseColor": "#FFFFFF",
      "paused": false,
      "reverse": false,
      "hardwareAccelerated": true,
    });
  }

  public getFailedRouteAntPath(waypoints: Waypoint[], blueColor: boolean): AntPath {
    return new AntPath(waypoints, {
      "delay": 300,
      "weight": 6,
      "color": blueColor ? "#059cdb" : "#e0315d",
      "pulseColor": "#FFFFFF",
      "paused": true,
      "reverse": false,
      "hardwareAccelerated": true
    });
  }

  public drawBatteryReservedWarningForIcon(): DivIcon {
    const batteryReservedWarningHtml = '<div class="battery-reserved-warning-icon">!</div>';
    return L.divIcon({ html: batteryReservedWarningHtml, iconAnchor: [0, 42], iconSize: [17, 21] });
  }

  public initChargerIcons() {
    this.offscreenCanvas = document.createElement('canvas');
    this.offscreenCanvas.width = CHARGER_GROUP_SIZES[CHARGER_GROUP_SIZES.length - 1] * CHARGER_GROUP_COLORS.length;
    this.offscreenCanvas.height = this.getArraySum(CHARGER_GROUP_SIZES);
    const offscreenCtx = this.offscreenCanvas.getContext('2d');

    CHARGER_GROUP_COLORS.forEach((color, index) => {
      CHARGER_GROUP_SIZES.forEach((size, sizeIndex) => {
        this.drawChargerIcon(offscreenCtx, size * index + (size / 2),
          this.getArraySum(CHARGER_GROUP_SIZES.slice(0, sizeIndex)) + CHARGER_GROUP_SIZES[sizeIndex] / 2, size / 2, color.color, color.skin);
      });
    });
  }

  private getArraySum(array: number[]) {
    return array.reduce((accumulator, currentValue) => { return accumulator + currentValue }, 0);
  }

  private drawChargerIcon(ctx, cx, cy, radius, mainColor, skin: MapSkin) {
    ctx.beginPath();
    ctx.fillStyle = mainColor;
    ctx.arc(cx, cy, radius, 0, 2 * Math.PI);
    ctx.globalAlpha = 0.4;
    ctx.fill();

    if (skin == MapSkin.Light) {
      ctx.beginPath();
      ctx.arc(cx, cy, radius * 0.64, 0, 2 * Math.PI);
      ctx.globalAlpha = 1;
      ctx.fillStyle = "#A1A1A1";
      ctx.fill();
    }

    ctx.beginPath();
    ctx.arc(cx, cy, radius * 0.61, 0, 2 * Math.PI);
    ctx.fillStyle = "#FFF";
    ctx.fill();
    ctx.beginPath();
    ctx.arc(cx, cy, radius * 0.64, 0, 2 * Math.PI);
    ctx.globalAlpha = 1;
    ctx.fillStyle = skin == MapSkin.Light ? "#A1A1A1" : "#FFF";
    ctx.fill();
    ctx.beginPath();
    ctx.arc(cx, cy, radius * 0.61, 0, 2 * Math.PI);
    ctx.fillStyle = "#FFF";
    ctx.fill();
    ctx.beginPath();
    ctx.arc(cx, cy, radius * 0.44, 0, 2 * Math.PI);
    ctx.fillStyle = mainColor;
    ctx.fill();
    ctx.closePath();
  }

  public getOffscreenCanvas() {
    return this.offscreenCanvas;
  }

  public getItemCoordOnCanvas(powerId: number, mapZoom: number, skin: MapSkin): [number, number, number, number] {
    if (skin == MapSkin.Light) {
      powerId += 3;
    }

    let sizeIdx = this.getSizeIdxFromMapZoom(mapZoom);

    const xCoord = (powerId - 1) * CHARGER_GROUP_SIZES[sizeIdx];
    const yCoord = sizeIdx > 0 ? this.getArraySum(CHARGER_GROUP_SIZES.slice(0, sizeIdx)) : 0;
    return [xCoord, yCoord, CHARGER_GROUP_SIZES[sizeIdx], CHARGER_GROUP_SIZES[sizeIdx]];
  }

  public getCanvasChargerSize(mapZoom: number): number {
    return CHARGER_SIZES[this.getSizeIdxFromMapZoom(mapZoom)];
  }

  public getCanvasChargerGroupSize(mapZoom: number): number{
    return CHARGER_GROUP_SIZES[this.getSizeIdxFromMapZoom(mapZoom)];
  }

  private getSizeIdxFromMapZoom(mapZoom: number){
    if (mapZoom < 14) {
      return 0
    }
    else if (mapZoom < 15) {
      return 1;
    }
    else if (mapZoom < 17) {
      return 2;
    }
    else {
     return 3;
    }
  }

  public getIncidentIcon(incident: Incident, mapSkin:MapSkin): { iconHtml: string, tooltip: string } {
    let iconName = '';
    let tooltip = '';
    let backgroundIcon = '';

    // Determine the incident type icon
    switch (incident.Type) {
      case 'accident':
        iconName = 'accident.svg';
        tooltip = 'Accident';
        break;
      case 'construction':
        iconName = 'construction.svg';
        tooltip = 'Construction Work';
        break;
      case 'congestion':
        iconName = 'congestion.svg';
        tooltip = 'Congestion';
        break;
      case 'disabledVehicle':
        iconName = 'disabled_vehicle.svg';
        tooltip = 'Disabled Vehicle';
        break;
      case 'massTransit':
        iconName = 'mass_transit.svg';
        tooltip = 'Mass Transit Disruption';
        break;
      case 'plannedEvent':
        iconName = 'planned_event.svg';
        tooltip = 'Planned Event';
        break;
      case 'roadHazard':
        iconName = 'road_hazard.svg';
        tooltip = 'Road Hazard';
        break;
      case 'roadClosure':
        iconName = 'road_closure.svg';
        tooltip = 'Road Closure';
        break;
      case 'weather':
        iconName = 'weather_disruption.svg';
        tooltip = 'Weather Disruption';
        break;
      case 'laneRestriction':
        iconName = 'lane_restriction.svg';
        tooltip = 'Lane Restriction';
        break;
      case 'other':
        iconName = 'other_incident.svg';
        tooltip = 'Other Incident';
        break;
      default:
        iconName = 'unknown.svg';
        tooltip = 'Unknown Incident';
        break;
    }

    // Determine the background icon based on severity
    switch (incident.Severity) {
      case 'critical':
        backgroundIcon = 'incidents_icon_background_critical.svg';
        break;
      case 'major':
        backgroundIcon = 'incidents_icon_background_major.svg';
        break;
      case 'minor':
        backgroundIcon = 'incidents_icon_background_minor.svg';
        break;
      default:
        backgroundIcon = 'incidents_icon_background_minor.svg'; // default to minor if unknown
        break;
    }

    return {
      iconHtml: `
              <div class='incident-icon' style='position: relative;'>
                  <img src='assets/icons/${mapSkin}/map/incidents/${backgroundIcon}' style='position: absolute; top: 0; left: 0;' />
                  <img src='assets/icons/${mapSkin}/map/incidents/${iconName}' style='position: absolute; top: 0; left: 0;' alt='${tooltip}' />
              </div>
          `,
      tooltip
    };
  }

  public getRoadSurfaceAlertIcon(alert: RoadSurfaceAlert, mapSkin: MapSkin): { iconHtml: string, tooltip: string } {
    let iconName = '';
    let tooltip = '';
    let backgroundIcon = '';

    // Determine the alert type icon
    switch (alert.Type) {
      case 'roughness':
        iconName = 'roughness.svg';
        tooltip = 'Road Surface Roughness';
        break;
      case 'hydroplaning':
        iconName = 'hydroplaning.svg';
        tooltip = 'Hydroplaning';
        break;
      case 'road_damage':
        iconName = 'road_damage.svg';
        tooltip = 'Road Damage';
        break;
      case 'slipperiness':
        iconName = 'slipperiness.svg';
        tooltip = 'Slipperiness';
        break;
      default:
        iconName = 'roughness.svg';
        tooltip = 'Unknown Alert';
        break;
    }

    // Determine the background icon based on severity
    if (alert.Severity >= 0.75) {
      backgroundIcon = 'icon_background_critical.svg';
    } else if (alert.Severity >= 0.5) {
      backgroundIcon = 'icon_background_major.svg';
    } else {
      backgroundIcon = 'icon_background_minor.svg';
    }

    return {
      iconHtml: `
              <div class='alert-icon' style='position: relative;'>
                  <img src='assets/icons/${mapSkin}/map/road_surface_alerts/${backgroundIcon}' style='position: absolute; top: 0; left: 0;' />
                  <img src='assets/icons/${mapSkin}/map/road_surface_alerts/${iconName}' style='position: absolute; top: 0; left: 0;' alt='${tooltip}' />
              </div>
          `,
      tooltip
    };
  }
}
