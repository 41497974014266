import { Component, OnInit, ChangeDetectionStrategy, HostListener, NgZone, Input } from '@angular/core';
import { tileLayer, icon, LatLng, LatLngBounds, Point, TileLayer, FeatureGroup, LayerGroup, LeafletMouseEvent, Polygon, Marker, TileLayerOptions } from 'leaflet';
import 'leaflet';

import * as martinez from 'martinez-polygon-clipping';
import * as canvastitle from 'tilelayer-canvas';
import { MatDialog } from '@angular/material/dialog';
import { CanvasLayer } from 'leaflet-canvas-layer';
import { fromEvent, of, Subscription, timer } from 'rxjs';
import { AntPath } from 'leaflet-ant-path';
import { MapService, ShowOnMapEnum } from 'src/app/services/map.service';
import { InputParamsService } from 'src/app/services/input-params.service';
import { UtilsService } from 'src/app/services/utils.service';
import device from 'current-device';
import { first, skip } from 'rxjs/operators';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { SettingsService, Unit, MapSkin, AddressType } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { AccountService, SubscriptionType } from 'src/app/services/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChargerProvider, ChargingGroup, ChargingStation, ChargingStationDetails } from 'src/app/models/charging-station';
import { WindElement } from 'src/app/models/wind';
import { Route, Incident, RoadSurfaceAlert, RoutePlanElement, RoutePlanType } from 'src/app/models/route';
import { Rha } from 'src/app/models/rha';
import { MapSettings } from 'src/app/models/map-settings';
import { TripPoint } from 'src/app/models/trip';
import { Address } from 'src/app/models/address';
import { SearchedRouteElement } from 'src/app/models/searched-route-element';
import { InitCanvasTiles } from './canvas-tiles';
import { MapDrawerHelperService } from 'src/app/services/map-drawer-helper.service';

declare const L: any;
const LOD_MULTIPLY = [600000, 150000, 35000, 10000, 1800, 500, 200, 100];
const BLUE_TO_GREY = ["#6FCEF1", "#7bcdea", "#89cce4", "#8dbed0", "#8baebb", "#859ca5", "#748186", "#62696b", "#4c4f50", "#3b3b3c"];

export const enum PopupAlign {
  Left,
  Right,
  Top
}

export enum MapElementType {
  Address = "address",
  FavoriteChargingStation = "favoriteChargingstation",
  DisabledChargingStation = "disabledChargingstation",
  Chargingstation = "chargingstation",
  RouteHistory = "routeHistory"
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MapComponent implements OnInit {
  @HostListener('window:resize') onResize() {
    document.getElementById("leaflet").setAttribute("style", "width: " + window.innerWidth + "px; height: " + window.innerHeight + "px;");
    setTimeout(() => {
      if (this.map) {
        document.getElementById("leaflet").setAttribute("style", "width: " + window.innerWidth + "px; height: " + window.innerHeight + "px;");
        this.map.invalidateSize();
      }
    }, 750);
  }
  @Input() showSliders: boolean;
  public map: L.Map;
  private scale: L.Control.Scale;
  private lodCurrent: number;
  private mapLayer: FeatureGroup;
  public polyLayer: FeatureGroup;
  public markerLayer: FeatureGroup;
  private chargingStationLayer: FeatureGroup;
  private chargingStationsTileLayer: LayerGroup;
  private chargingStationsDetailed: boolean = false;
  private favoriteChargingStationsDetailed: boolean = false;
  private chargingStationRecalc: boolean = false;
  private showOnMap: boolean = false;
  private contextMenuLayer: FeatureGroup;
  private searchLayer: FeatureGroup;
  private tripLayer: FeatureGroup;
  private activeTripLayer: FeatureGroup;
  public canvasLayer: CanvasLayer;
  private rangeInnerPolys: Polygon[] = [];
  private rangeOuterPolys: Polygon[] = [];
  private chargingStationBoundStatus: any = {};
  private chargerStatusTimer = new Subscription();

  private chargingStationsInsideBoundingBox: ChargingStation[] = [];
  private favoriteChargingStationsInsideBoundingBox: ChargingStation[] = [];
  private chargingGroupsInsideBoundingBox: ChargingGroup[] = [];
  private canvasHoverSubscriptions: Subscription[] = [];
  private chargerPopupSubscriptions: Subscription[] = [];
  private chargingStationsPixelPoints: Point[] = [];
  private chargingGroupsPixelPoints: Point[] = [];
  private favoriteChargingStationPixelPoints: Point[] = [];
  private bigChargingStations: LatLng[] = [];
  private lastBoundingBox: L.LatLngBounds = null;
  private lastTileBounds: string[] = [];
  private lastOpenedChargingStationPopup: ChargingStationDetails;

  private pulseInterval: ReturnType<typeof setTimeout>;
  private subscriptions: Subscription[] = [];

  private darkMapLayer;
  private darkMapLayerPOI;

  private lightMapLayer;
  private lightMapLayerPOI;

  private darkAdminLayer;
  private lightAdminLayer;

  private csLayer: TileLayer;

  public windDatas: WindElement[] = [];

  private lastRouteData: Route = null;
  private lastWaypoints: LatLng[];
  private lastRha: Rha;

  private touchEventSubscriptions: Subscription[] = [];
  private tileVersion: String = "";

  constructor(public matDialog: MatDialog, private mapService: MapService, private inputParamsService: InputParamsService, private utilsService: UtilsService,
    public mobileResolutionService: MobileResolutionService, private snackBar: MatSnackBar, public settingsService: SettingsService, private _ngZone: NgZone,
    public languageService: LanguageService, private consoleLoggerService: ConsoleLoggerService, public accountService: AccountService,
    private mapDrawerHelperService: MapDrawerHelperService) {

    this.tileVersion = this.mapService.getChargingStationVerions();
    InitCanvasTiles();
    this.initTileLayers();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    document.getElementById("leaflet").setAttribute("style", "width: " + window.innerWidth + "px; height: " + window.innerHeight + "px;");
    if (navigator.userAgent.includes('FBIOS')) {
      setTimeout(() => {
        document.getElementById("leaflet").setAttribute("style", "width: " + window.innerWidth + "px; height: " + window.innerHeight + "px;");
        this.map.invalidateSize();
      }, 500);
    }
    this.map = this.mapDrawerHelperService.getMapComponent(this.inputParamsService.getStartCoordsParams());
    this.map.invalidateSize();
    this.map.createPane("windPane");

    this.setScale();
    const southWest = L.latLng(-89.98155760646617, -320);
    const northEast = L.latLng(89.99346179538875, 320);
    const bounds = L.latLngBounds(southWest, northEast);
    this.map.setMaxBounds(bounds);
    this.mapService.setMapBounds(this.map.getBounds());
    this.lodCurrent = this.utilsService.getLodLevel(this.map);
    this.map.options.zoomAnimation == true;

    // define layer group
    this.mapLayer = L.featureGroup().addTo(this.map);
    if (this.settingsService.getMap()) {
      if (this.settingsService.getMap().Skin === MapSkin.Light) {
        if (this.settingsService.getMap().POIs) {
          this.lightMapLayerPOI.addTo(this.mapLayer);
        }
        else {
          this.lightMapLayer.addTo(this.mapLayer);
        }
        this.lightAdminLayer.addTo(this.mapLayer);
      }
      else {
        if (this.settingsService.getMap().POIs) {
          this.darkMapLayerPOI.addTo(this.mapLayer);
        }
        else {
          this.darkMapLayer.addTo(this.mapLayer);
        }
        this.darkAdminLayer.addTo(this.mapLayer);
      }
    }
    else {
      // default
      this.darkMapLayer.addTo(this.mapLayer);
      this.darkAdminLayer.addTo(this.mapLayer);
    }

    this.markerLayer = L.featureGroup().addTo(this.map);
    this.polyLayer = L.featureGroup().addTo(this.map);
    this.tripLayer = L.featureGroup().addTo(this.map);
    this.activeTripLayer = L.featureGroup().addTo(this.map);
    this.chargingStationLayer = L.featureGroup().addTo(this.map);
    this.contextMenuLayer = L.featureGroup().addTo(this.map);
    this.searchLayer = L.featureGroup().addTo(this.map);
    this.chargingStationsTileLayer = L.featureGroup().addTo(this.map);
    // canvas layer for wind icons
    this.canvasLayer = new CanvasLayer().delegate(this).addTo(this.map);

    this.csLayer.addTo(this.chargingStationsTileLayer);

    this.map.on('zoomstart', (evt) => {
      (this.canvasLayer as any)._canvas.getContext('2d').clearRect(0, 0, (this.canvasLayer as any)._canvas.width, (this.canvasLayer as any)._canvas.height);
    });

    // zoom change subscription, re set lod level, add route and arrows depending on lod level
    this.map.on('zoomend', (evt) => {
      if (this.map.getZoom() <= 6) {
        this.map.getPane('windPane').style.display = 'none';
      }
      else {
        this.map.getPane('windPane').style.display = 'block';
      }
      if (this.map.getZoom() > 11 && !this.chargingStationsDetailed) {
        this.chargingStationsTileLayer.removeFrom(this.map);
        this.chargingStationsDetailed = true;
      }
      if (this.map.getZoom() <= 11 && this.chargingStationsDetailed) {
        this.chargingStationsTileLayer.addTo(this.map);
        this.chargingStationsDetailed = false;
        this.chargingStationsInsideBoundingBox = [];
        this.chargingGroupsInsideBoundingBox = [];
        this.chargingStationsPixelPoints = [];
        this.chargingGroupsPixelPoints = [];
      }
      if (this.map.getZoom() > 7 && !this.favoriteChargingStationsDetailed) {
        this.favoriteChargingStationsDetailed = true;
        this.canvasHoverSubscriptions.forEach(element => {
          element.unsubscribe();
        });
        this.canvasHoverSubscriptions.push(fromEvent(this.map, 'click').subscribe((e: any) => {
          if (e.originalEvent.isTrusted) {
            this.canvasMouseClick(e);
          }
        }));
      }
      if (this.map.getZoom() <= 7 && this.favoriteChargingStationsDetailed) {
        this.favoriteChargingStationsDetailed = false;
        this.canvasHoverSubscriptions.forEach(element => {
          element.unsubscribe();
        });
        //this.favoriteChargingStationsInsideBoundingBox = [];
        //this.favoriteChargingStationPixelPoints = [];
        this.lastTooltip = null;
      }

      this.getExtendedBoundingBox();

      this.mapService.setMapBounds(this.map.getBounds());

      const clampedLod = (this.map.getZoom() < 11 ? 11 : this.map.getZoom());
      const innerOpacity: number = 0.3 * (1.0 - (((clampedLod > 16 ? 16 : clampedLod) - 11) / 5.0));
      const outerOpacity: number = 0.5 * (1.0 - (((clampedLod > 16 ? 16 : clampedLod) - 11) / 5.0));
      for (let polyIdx = 0; polyIdx < this.rangeInnerPolys.length; polyIdx++) {
        this.rangeInnerPolys[polyIdx].options.fillOpacity = innerOpacity;
      }
      for (let polyIdx = 0; polyIdx < this.rangeOuterPolys.length; polyIdx++) {
        this.rangeOuterPolys[polyIdx].options.fillOpacity = outerOpacity;
      }
    });

    // map area changed subscription
    this.map.on('dragend', (e) => {
      if (this.lastBoundingBox == null || !this.lastBoundingBox.contains(this.map.getBounds())) {
        this.getExtendedBoundingBox();
      }
      this.mapService.setMapBounds(this.map.getBounds());
    });

    fromEvent(this.map, 'click').subscribe((e: any) => {
      if (e.originalEvent.isTrusted) {
        if (!this.isCSTooltip(e)) {
          this.closeCSTooltip();
        }
        this.mapService.ObservableActiveInfoSidebarRouteElement.next({ type: 'clear' });
      }
    });

    // IOS  context menu fix
    if (device.ios() || device.iphone() || device.ipad() || device.ipod()) {
      let longPress: boolean = false;
      const leafletMap = document.getElementById('leaflet');
      const touchStart = fromEvent(leafletMap, 'touchstart');
      const touchMove = fromEvent(leafletMap, 'touchmove');
      const touchEnd = fromEvent(leafletMap, 'touchend');
      let pressTimeout;
      let startXY;

      touchStart.subscribe((event: any) => {
        if (event.isTrusted && !this.showOnMap) {
          //event.preventDefault();
          longPress = true;
          startXY = [event.touches[0].clientX, event.touches[0].clientY];
          let latlng = this.map.containerPointToLatLng([event.touches[0].clientX, event.touches[0].clientY]);
          clearTimeout(pressTimeout);
          pressTimeout = setTimeout(() => {
            if (longPress) {
              event.stopImmediatePropagation();
              event.preventDefault();
              event.stopPropagation();
              this.openContextMenu(latlng);
            }
          }, 600);
        }
      });

      touchMove.subscribe((event: any) => {
        if (event.isTrusted) {
          if (longPress && (Math.abs(event.touches[0].clientX - startXY[0]) + Math.abs(event.touches[0].clientY - startXY[1])) > 50) {
            longPress = false;
          }
        }
      });

      touchEnd.subscribe((event) => {
        if (event.isTrusted) {
          longPress = false;
        }
      });
    } else {
      // context menu subscription
      this.map.on('contextmenu', (e: LeafletMouseEvent) => {
        if (!this.showOnMap) {
          this.openContextMenu(e.latlng);
        }
      });
    }

    this.subscriptions.push(this.settingsService.ObservableMap.pipe(skip(1)).subscribe((mapsettings: MapSettings) => {
      if (mapsettings) {
        const skin = mapsettings.Skin;
        const displayPOIS = mapsettings.POIs;

        const zoomlevel = this.map.getZoom();
        this.mapLayer.clearLayers();
        this.chargingStationLayer.clearLayers();
        if (skin == "light") {
          if (displayPOIS) {
            this.lightMapLayerPOI.addTo(this.mapLayer);
          } else {
            this.lightMapLayer.addTo(this.mapLayer);
          }
          this.lightAdminLayer.addTo(this.mapLayer);
          if (this.inputParamsService.getSelectedMode() == "rha") {
            const cursor = document.querySelectorAll("#rhamarker-outer .cursor-marker");
            if (cursor != undefined && cursor.length > 0) {
              cursor[0].setAttribute("src", "assets/icons/light/map/cursor.png");
            }
          }
          else {
            const failedPlan = this.inputParamsService.getFailedPlan();
            if (this.inputParamsService.getRangeParams().Waypoints.length < 2) {
              this.drawOnePointRouteToMap();
            }
            else if (failedPlan && failedPlan.failedPlan) {
              this.drawFailedRouteToMap(failedPlan.maxReachedWaypoint);
            }
            else if (this.lastRouteData) {
              this.drawRouteToMap(this.lastRouteData);
            }
          }

        }
        else {
          if (displayPOIS) {
            this.darkMapLayerPOI.addTo(this.mapLayer);
          } else {
            this.darkMapLayer.addTo(this.mapLayer);
          }
          this.darkAdminLayer.addTo(this.mapLayer);
          if (this.inputParamsService.getSelectedMode() == "rha") {
            const cursor = document.querySelectorAll("#rhamarker-outer .cursor-marker");
            if (cursor != undefined && cursor.length > 0) {
              cursor[0].setAttribute("src", "assets/icons/dark/map/cursor.png");
            }
          }
          else {
            const failedPlan = this.inputParamsService.getFailedPlan();
            if (this.inputParamsService.getRangeParams().Waypoints.length < 2) {
              this.drawOnePointRouteToMap();
            }
            else if (failedPlan && failedPlan.failedPlan) {
              this.drawFailedRouteToMap(failedPlan.maxReachedWaypoint);
            }
            else if (this.lastRouteData) {
              this.drawRouteToMap(this.lastRouteData);
            }
          }
        }
        if (zoomlevel <= 11) {
          this.chargingStationsTileLayer.removeFrom(this.map);
          this.chargingStationsTileLayer.addTo(this.map);
        }
        this.map.setZoom(zoomlevel);
      }
    }));

    // charging stations changed subscription
    this.subscriptions.push(this.mapService.ObservableChargingStations.subscribe((resp) => {
      if (resp != undefined) {
        this.setChargingStationsLayers(resp);
        if (this.chargingStationsDetailed) {
          this.redrawCanvas();
        }
      }
    }));

    // searched location subscription
    this.subscriptions.push(this.mapService.ObservableSearchedLocation.subscribe((resp) => {
      if (resp != undefined) {
        this.navigateToLocation(resp);
      }
    }));

    // selected coordinate subscription
    this.subscriptions.push(this.mapService.ObservableSelectedCoordinate.subscribe((coordinate) => {
      if (coordinate != undefined) {
        this.map.setView(coordinate, 18);
        this.searchLayer.clearLayers();
        L.marker(coordinate, { interactive: false, icon: L.divIcon({ html: "<div class='pulse'></div>", iconAnchor: [11, 11], iconSize: [22, 22] }) }).addTo(this.searchLayer);
        clearTimeout(this.pulseInterval);
        this.pulseInterval = setTimeout(() => this.searchLayer.clearLayers(), 3000);
      }
    }));

    this.subscriptions.push(this.mapService.ObservableDrawTripToMap.subscribe((resp: TripPoint[]) => {
      if (resp && resp.length > 0) {
        this.drawTripToMap(resp);
      }
    }));

    this.subscriptions.push(this.mapService.ObservableMoveTo.subscribe((resp) => {
      if (resp) {
        this.map.flyTo(resp.latlng, resp.zoom, { animate: false });
      }
    }));

    this.subscriptions.push(this.mapService.ObservableCursorColor.subscribe((resp) => {
      if (resp == true) {
        this.setStartMarkerColor(resp);
      }
      if (resp == false) {
        this.setStartMarkerColor(resp);
      }
    }));

    this.subscriptions.push(this.settingsService.ObservableUnit.subscribe((resp) => {
      if (resp) {
        let speedlimits = document.getElementsByClassName("speedlimit-value");
        if (resp.Speed == Unit.Metric) {
          for (let i = 0; i < speedlimits.length; i++) {
            let speedVal = parseFloat(speedlimits[i].getAttribute("value"));
            speedlimits[i].innerHTML = speedVal.toString();
          }
        }
        if (resp.Speed == Unit.Imperial) {
          for (let i = 0; i < speedlimits.length; i++) {
            let speedVal = parseFloat(speedlimits[i].getAttribute("value"));
            speedVal = Math.round(this.utilsService.kmphToMph(speedVal));
            speedlimits[i].innerHTML = speedVal.toString();
          }
        }
        this.setScale();
      }
    }));

    this.subscriptions.push(this.mapService.ObservablePositionMapToRoute.subscribe((resp) => {
      if (resp) {
        if (this.chargingStationRecalc) {
          this.chargingStationRecalc = false;
          this.redrawCanvas();
          /** redraw charging station popup */
          if (this.lastOpenedChargingStationPopup && !this.showOnMap) {
            this.redrawChargingStationPopup(this.lastOpenedChargingStationPopup);
          }
        }
        else {
          this.positionMapToRoute(resp);
        }
      }
    }));

    this.subscriptions.push(this.mapService.ObservableSearchedMapElement.subscribe((resp) => {
      if (resp && resp.elementType) {
        this.searchLayer.clearLayers();
        if (resp.elementType == MapElementType.Address) {
          this.map.flyTo(resp.mapElement.Location, 15, { animate: false });
          this.drawAddressMarkerToMap(resp.mapElement);
        }
        if (resp.elementType == MapElementType.FavoriteChargingStation || resp.elementType == MapElementType.DisabledChargingStation) {
          if (device.mobile()) {
            this.map.flyTo(new LatLng(resp.mapElement.lat, resp.mapElement.lon), 15, { animate: false });
            const mapCenterPoint: L.Point = this.map.project(new LatLng(resp.mapElement.Location.lat, resp.mapElement.Location.lng), this.map.getZoom());
            const height = this.map.getSize().y;
            const calculatedPoint = new Point(mapCenterPoint.x, mapCenterPoint.y - height * 0.25);

            this.map.flyTo(this.map.unproject(calculatedPoint, this.map.getZoom()), 15, { animate: false });
            /** vertically aligned popup */
            this.drawChargingStationPopup(PopupAlign.Top, 0, 20, resp.mapElement, false, false, true, false);
          }
          else {
            this.map.flyTo(new LatLng(resp.mapElement.lat, resp.mapElement.lon), 15, { animate: false });
            /** vertically aligned popup */
            this.drawChargingStationPopup(PopupAlign.Left, 20, 0, resp.mapElement, false, false, true, false);
          }

          this.redrawCanvas();
        }
        if (resp.elementType == MapElementType.Chargingstation) {
          this.drawChargingStationPopup(PopupAlign.Top, 0, 80, resp.mapElement, true, false, false, true);
        }
      }
    }));

    this.subscriptions.push(this.mapService.ObservableShowOnMap.subscribe((resp) => {
      if (resp != null) {
        if (resp.type && resp.type == ShowOnMapEnum.BackSettings) {
          this.searchLayer.clearLayers();
          this.showOnMap = false;
        }
        else if (resp.type && resp.type == ShowOnMapEnum.BackHistory) {
          this.searchLayer.clearLayers();
          this.showOnMap = false;
          this.activeTripLayer.clearLayers();
          if (this.inputParamsService.getSelectedMode() == "route" && this.lastRouteData) {
            this.drawRouteToMap(this.lastRouteData);
            this.positionMapToRoute(this.lastWaypoints);
          }
          else if (this.inputParamsService.getSelectedMode() == "rha" && this.rangeInnerPolys) {
            this.updatePolys(this.lastRha, false);

            this.setMarker(new LatLng(this.inputParamsService.getStartCoordsParams().lat,
              this.inputParamsService.getStartCoordsParams().lng), 0);
            this.map.fitBounds(this.polyLayer.getBounds());
          }
        }
        else {
          this.showOnMap = true;
          this.closeCSTooltip();
        }
      }
    }));

    this.subscriptions.push(this.mapService.ObservableSetChargingStationRecalc.subscribe((resp) => {
      if (resp != null) {
        if (resp) {
          this.chargingStationRecalc = true;
          if (this.inputParamsService.getSelectedMode() == "route") {
            this.inputParamsService.paramsUpdate();
          }

          this.redrawCanvas();
        }
      }
    }));

    this.subscriptions.push(this.mapService.ObservableClearLastOpenedChargingStation.subscribe((resp) => {
      if (resp) {
        this.chargingStationLayer.clearLayers();
        this.lastOpenedChargingStationPopup = null;
      }
    }));

    this.subscriptions.push(this.languageService.ObservableLanguageLoaded.subscribe((resp) => {
      if (resp) {
        if (this.lastOpenedChargingStationPopup && !this.showOnMap) {
          this.redrawChargingStationPopup(this.lastOpenedChargingStationPopup);
        }
        if (this.inputParamsService.getSelectedMode() == "route" && this.lastRouteData) {
          this.drawRouteToMap(this.lastRouteData);
        }
      }
    }));
  }

  private initTileLayers() {
    this.darkMapLayer = new (TileLayer as any).Canvas('https://tiles.gpstuner.net/tile_evdark_en_v2/{z}/{x}/{y}.png?ver=' + this.tileVersion, this.getMapLayerOptions());
    this.darkMapLayerPOI = new (TileLayer as any).Canvas('https://tiles.gpstuner.net/tile_evdark_poi_en_v2/{z}/{x}/{y}.png?ver=' + this.tileVersion, this.getMapLayerOptions());
    this.lightMapLayer = new (TileLayer as any).Canvas('https://tiles.gpstuner.net/tile_evlight_en_v2/{z}/{x}/{y}.png?ver=' + this.tileVersion, this.getMapLayerOptions());
    this.lightMapLayerPOI = new (TileLayer as any).Canvas('https://tiles.gpstuner.net/tile_evlight_poi_en_v2/{z}/{x}/{y}.png?ver=' + this.tileVersion, this.getMapLayerOptions());
    this.darkAdminLayer = new (TileLayer as any).Canvas('https://tiles.gpstuner.net/tile_evdark_admin_en_v2/{z}/{x}/{y}.png?ver=' + this.tileVersion, this.getAdminLayerOptions());
    this.lightAdminLayer = new (TileLayer as any).Canvas('https://tiles.gpstuner.net/tile_evlight_admin_en_v2/{z}/{x}/{y}.png?ver=' + this.tileVersion, this.getAdminLayerOptions());

    this.csLayer = new (TileLayer as any).Canvas('https://staging.evnavigation.com/chargingstations/tiles/63/{z}_{x}_{y}_63.png?ver=20240524', this.getChargingStationOptions());
  }

  private getMapLayerOptions(): TileLayerOptions {
    return { maxZoom: 18, minZoom: 3, crossOrigin: true };
  }

  private getAdminLayerOptions(): TileLayerOptions {
    return { maxZoom: 11, minZoom: 3, zIndex: 5, crossOrigin: true };
  }

  private getChargingStationOptions(): TileLayerOptions {
    return { maxZoom: 11, minZoom: 3, zIndex: 4, tileSize: 2048, zoomOffset: -3 };
  }

  private openContextMenu(latlng: LatLng): void {
    this.touchEventSubscriptions.forEach((sub) => { sub.unsubscribe(); });
    this.touchEventSubscriptions = [];
    this.contextMenuLayer.clearLayers();

    const placeCursorIcon: L.Icon = this.mapDrawerHelperService.getPlaceCursorIcon(this.settingsService.getMap().Skin);
    const placeCursorOffset: L.Point = new L.Point(-45, -100);
    const routeCursorIcon: L.Icon = this.mapDrawerHelperService.getRouteCursorIcon(this.settingsService.getMap().Skin, this.inputParamsService.getSelectedMode());
    const routeCursorOffset: L.Point = new L.Point(42, -100);
    this.mapDrawerHelperService.getContextMenuCircle(latlng, this.settingsService.getMap().Skin).addTo(this.contextMenuLayer);

    const mS = L.marker(latlng, { icon: placeCursorIcon, zIndexOffset: 2000 }).addTo(this.contextMenuLayer).on('click', (e: any) => {
      // normalize coords
      const wrappedLatLng = this.map.wrapLatLng(e.latlng);
      if (wrappedLatLng.lat != e.latlng.lat || wrappedLatLng.lng != e.latlng.lng) {
        e.latlng = wrappedLatLng;
        this.map.flyTo(e.latlng, this.map.getZoom(), { animate: false });
      }

      this.inputParamsService.setStartCoordsParams(e.latlng);
      this.mapService.getReverseGeocode(e.latlng.lat, e.latlng.lng).subscribe((reversegeocode) => {
        if (reversegeocode) {
          this.inputParamsService.ObservableSetStartPointGeocode.next({
            display_name: reversegeocode,
            lat: e.latlng.lat,
            lng: e.latlng.lng,
            wp: 0
          });
        }
      }, () => { });
    });

    mS._icon.id = "set-start-icon";
    if (!device.ios() && !device.iphone() && !device.ipad() && !device.ipod()) {
      mS.bindTooltip(this.languageService.languageJSON.Map_Tooltip_SetStartCoord, { direction: "top", offset: placeCursorOffset, opacity: 1.0, className: "context-tooltip" });
    }

    if (this.inputParamsService.getSelectedMode() == "route") {
      mS._icon.classList.add("inactive");
    }

    const mE = L.marker(latlng, { icon: routeCursorIcon, zIndexOffset: 2000 }).addTo(this.contextMenuLayer).on('click', (e: any) => {

      const wrappedLatLng = this.map.wrapLatLng(e.latlng);
      if (wrappedLatLng.lat != e.latlng.lat || wrappedLatLng.lng != e.latlng.lng) {
        e.latlng = wrappedLatLng;
      }

      this.inputParamsService.ObservableReverseGeocodingLoader.next(true);
      this.mapService.getReverseGeocode(e.latlng.lat, e.latlng.lng).subscribe((reversegeocode) => {
        if (reversegeocode) {
          this.inputParamsService.addEndpoint({
            display_name: reversegeocode,
            lat: e.latlng.lat,
            lng: e.latlng.lng
          });
        }
      });
    });

    mE._icon.id = "set-end-icon";

    if (!device.ios() && !device.iphone() && !device.ipad() && !device.ipod()) {
      mE.bindTooltip(this.languageService.languageJSON.Map_Tooltip_SetEndCoord, { direction: "top", offset: routeCursorOffset, opacity: 1.0, className: "context-tooltip" });
    }

    if (device.ios() || device.iphone() || device.ipad() || device.ipod()) {
      const leafletMap = document.getElementById('leaflet');
      this.touchEventSubscriptions.push(fromEvent(leafletMap, 'touchend').pipe(first()).subscribe(() => {
        this.touchEventSubscriptions.push(fromEvent(leafletMap, 'touchstart').pipe(first()).subscribe(() => {
          this.touchEventSubscriptions.push(fromEvent(leafletMap, 'click').pipe(first()).subscribe(() => {
            this.contextMenuLayer.clearLayers();
          }));
        }));
      }));
    }
    else {
      fromEvent(document, 'click').pipe(first()).subscribe((event: any) => {
        this.contextMenuLayer.clearLayers();
      });
    }

    if (this.inputParamsService.getWaypointsParams().length >= 5) {
      mE._icon.classList.add("inactive");
      this.snackBar.open(this.languageService.languageJSON.InfoSidebar_WaypointLimitReached, "", { duration: 5000, panelClass: 'snackbar-info' });
    }
  }

  setScale() {
    if (this.scale) {
      this.scale.remove();
    }

    this.scale = L.control.scale({ metric: this.settingsService.getUnit().Distance === Unit.Metric, imperial: this.settingsService.getUnit().Distance === Unit.Imperial }).addTo(this.map);
  }

  // Draw RHA center marker to the map
  public setMarker(pos: L.LatLng, bearing: number): void {
    this.markerLayer.clearLayers();
    this.bigChargingStations = [];
    L.marker(pos, { interactive: false, icon: this.mapDrawerHelperService.getRhaCursorDivIcon(this.settingsService.getMap().Skin, bearing) }).addTo(this.markerLayer);
  }

  private setStartMarkerColor(colored: boolean): void {
    const startMarker = document.getElementById("rhamarker-outer");
    if (startMarker) {
      if (colored) {
        startMarker.setAttribute("style", "");
      }
      else {
        startMarker.setAttribute("style", "-webkit-filter: grayscale(100%); filter: grayscale(100%);");
      }
    }
  }

  // for failed route planning
  private setStartEndMarkers(startPos: LatLng, endPos: LatLng, failedPlan: boolean, trip: boolean): void {
    let startMarker: Marker = this.mapDrawerHelperService.getStartMarker(this.settingsService.getMap().Skin, startPos);
    let endMarker: Marker = this.mapDrawerHelperService.getEndMarker(this.settingsService.getMap().Skin, endPos, failedPlan);
    startMarker.addTo(this.markerLayer);
    endMarker.addTo(this.markerLayer);

    if (!trip && !failedPlan) {
      startMarker.on('click', (e: any) => {
        this.mapService.ObservableActiveInfoSidebarRouteElement.next({ type: 'start' });
      });

      endMarker.on('click', (e: any) => {
        this.mapService.ObservableActiveInfoSidebarRouteElement.next({ type: 'end' });
      });
    }
  }

  // Draw RHA polylines to the map
  public updatePolys(rhaData: Rha, compare: boolean): void {
    this.polyLayer.clearLayers();
    this.lastRha = rhaData;
    this.rangeInnerPolys = [];
    this.rangeOuterPolys = [];

    const clampedLod = (this.map.getZoom() < 11 ? 11 : this.map.getZoom());
    const innerOpacity: number = 0.3 * (1.0 - (((clampedLod > 16 ? 16 : clampedLod) - 11) / 5.0));
    const outerOpacity: number = 0.5 * (1.0 - (((clampedLod > 16 ? 16 : clampedLod) - 11) / 5.0));

    if (compare) {
      // red poly
      rhaData.Range2.forEach(innerRange => {
        L.polyline(innerRange, { color: "#c71c1c" }).addTo(this.polyLayer);
      });
      // green poly
      rhaData.Range1.forEach(innerRange => {
        L.polyline(innerRange, { color: "#3CE6A7" }).addTo(this.polyLayer);
      });
    }
    else {
      if (document.URL.includes("polyline")) {

        // inner range
        rhaData.Range2.forEach(innerRange => {
          const poly = L.polygon(innerRange, { color: "#3CE6A7", fillOpacity: innerOpacity });
          this.rangeInnerPolys.push(poly);
          poly.addTo(this.polyLayer);
        });

        // outer range
        if (rhaData.Range1.length > 0 && rhaData.Range2.length > 0) {
          const outerRange: any = martinez.xor([this.utilsService.closeGeometry(rhaData.Range1)], [this.utilsService.closeGeometry(rhaData.Range2)]);
          const poly = L.polygon(outerRange, { color: "#27DB99", fillOpacity: innerOpacity }).addTo(this.polyLayer);
          this.rangeOuterPolys.push(poly);
        }
        else if (rhaData.Range1.length > 0) {
          rhaData.Range1.forEach(innerRange => {
            const poly = L.polygon(innerRange, { color: "#27DB99", fillOpacity: innerOpacity });
            this.rangeInnerPolys.push(poly);
            poly.addTo(this.polyLayer);
          });
        }

        // inner range
        rhaData.Range2.forEach(innerRange => {
          const poly = L.polyline(innerRange, { color: "#00FF00", fillOpacity: innerOpacity });
          this.rangeInnerPolys.push(poly);
          poly.addTo(this.polyLayer);
        });

        // outer range
        if (rhaData.Range1.length > 0) {
          rhaData.Range1.forEach(innerRange => {
            const poly = L.polyline(innerRange, { color: "#0000FF", fillOpacity: outerOpacity }).addTo(this.polyLayer);
            this.rangeOuterPolys.push(poly);
          });
        }
      }
      else {
        // inner range
        rhaData.Range2.forEach(innerRange => {
          const poly = L.polygon(innerRange, { color: "#3CE6A7", fillOpacity: innerOpacity });
          this.rangeInnerPolys.push(poly);
          poly.addTo(this.polyLayer);
        });

        // outer range
        if (rhaData.Range1.length > 0 && rhaData.Range2.length > 0) {
          const outerRange: any = martinez.xor([this.utilsService.closeGeometry(rhaData.Range1)], [this.utilsService.closeGeometry(rhaData.Range2)]);
          const poly = L.polygon(outerRange, { color: "#27DB99", fillOpacity: outerOpacity }).addTo(this.polyLayer);
          this.rangeOuterPolys.push(poly);
        }
        else if (rhaData.Range1.length > 0) {
          rhaData.Range1.forEach(innerRange => {
            const poly = L.polygon(innerRange, { color: "#27DB99", fillOpacity: innerOpacity });
            this.rangeInnerPolys.push(poly);
            poly.addTo(this.polyLayer);
          });
        }
      }

      if (this.inputParamsService.getSearchedRangeOrRoute()) {
        this.map.fitBounds(this.polyLayer.getBounds());
        this.inputParamsService.setSearchedRangeOrRoute(false);
      }
    }
  }

  // draw wind svg when actual wind selected
  public initWind(windElements: WindElement[]): void {
    this.windDatas = windElements;
    this.canvasLayer.needRedraw();
  }

  /* drawing with canvasLayer */
  public onDrawLayer(canvasEl: any): void {
    const ctx: CanvasRenderingContext2D = (canvasEl.canvas as HTMLCanvasElement).getContext('2d');
    ctx.clearRect(0, 0, canvasEl.canvas.width, canvasEl.canvas.height);
    // drawing arrows if wind data
    if (this.windDatas.length > 0 && this.map.getZoom() > 5) {
      // init canvas
      ctx.lineWidth = 1.5;
      if (this.settingsService.getMap().Skin === MapSkin.Light) {
        ctx.fillStyle = 'rgba(130, 130, 130, 1)';
        ctx.strokeStyle = 'rgba(130, 130, 130, 1)';
      }
      else {
        ctx.fillStyle = 'rgba(204, 204, 204, 1)';
        ctx.strokeStyle = 'rgba(204, 204, 204, 1)';
      }

      //loop wind arrows array
      if (this.map.getZoom() > 9) {
        this.windDatas.forEach(element => {
          this.drawWindToCanvas(ctx, element, canvasEl);
        });
      }
      else if (this.map.getZoom() > 7) {
        // if wind zoom 8 or 9 draw half wind
        let wRow = 0;
        let wRowVal = this.windDatas[0].Location.lng;
        let colSize = 0;
        for (let i = 0; i < this.windDatas.length; i++) {
          if (wRowVal != this.windDatas[i].Location.lng) {
            colSize = i;
            break;
          }
        }

        for (let i = 0; i < this.windDatas.length; i++) {
          if (wRowVal != this.windDatas[i].Location.lng) {
            wRow++;
            wRowVal = this.windDatas[i].Location.lng;
          }
          if (wRow % 2 == 0 && (colSize % 2 == 0 && i % 2 == 0 || colSize % 2 == 1 && i % colSize % 2 == 0)) {
            this.drawWindToCanvas(ctx, this.windDatas[i], canvasEl);
          }
        }
      }
      else {
        // if wind zoom 6 or 7 draw quarter wind
        let wRow = 0;
        let wRowVal = this.windDatas[0].Location.lng;
        let colSize = 0;
        for (let i = 0; i < this.windDatas.length; i++) {
          if (wRowVal != this.windDatas[i].Location.lng) {
            colSize = i;
            break;
          }
        }
        for (let i = 0; i < this.windDatas.length; i++) {
          if (wRowVal != this.windDatas[i].Location.lng) {
            wRow++;
            wRowVal = this.windDatas[i].Location.lng;
          }
          if (wRow % 4 == 0 && (colSize % 2 == 0 && i % 4 == 0 || colSize % 2 == 1 && i % colSize % 4 == 0)) {
            this.drawWindToCanvas(ctx, this.windDatas[i], canvasEl);
          }
        }
      }
    }

    if (this.chargingStationsInsideBoundingBox.length > 0 && this.map.getZoom() > 11) {
      this.chargingStationsPixelPoints = [];
      for (let i = 0; i < this.chargingStationsInsideBoundingBox.length; i++) {

        let imageId: string = this.chargingStationsInsideBoundingBox[i].img;
        let size: number;
        if (this.map.getZoom() < 14) {
          imageId += "_100";
          size = 30;
        }
        else if (this.map.getZoom() < 15) {
          imageId += "_125";
          size = 40;
        }
        else if (this.map.getZoom() < 17) {
          imageId += "_150";
          size = 50;
        }
        else {
          imageId += "_200";
          size = 60;
        }

        if (this.settingsService.getMap().Skin === MapSkin.Light) {
          imageId += "_light";
        }

        const image = document.getElementById(imageId) as HTMLImageElement;
        const centerImg = this.canvasLayer.map.latLngToContainerPoint([this.chargingStationsInsideBoundingBox[i].lat, this.chargingStationsInsideBoundingBox[i].lon]);
        this.chargingStationsPixelPoints.push(centerImg);

        if (this.chargingStationsInsideBoundingBox[i].transparentOp) {
          ctx.globalAlpha = 0.5;
        }
        else {
          ctx.globalAlpha = 1;
        }

        ctx.drawImage(image, centerImg.x - (size / 2), centerImg.y - (size / 2), size, size);
        ctx.lineWidth = 4;

        ctx.globalAlpha = 1;


        if (this.chargingStationBoundStatus[this.chargingStationsInsideBoundingBox[i].id] != null) {
          const stateMarkerColor = this.chargingStationBoundStatus[this.chargingStationsInsideBoundingBox[i].id] == 1 ? "#00d511" : "#ec0002";
          let outsideRadius = 10;
          let insideRadius = 7;
          let offset = 10;
          if (this.map.getZoom() < 14) {
            outsideRadius = 6;
            insideRadius = 4;
            offset = 9;
          }
          else if (this.map.getZoom() < 15) {
            outsideRadius = 7;
            insideRadius = 5;
            offset = 12;
          }
          else if (this.map.getZoom() < 17) {
            outsideRadius = 9;
            insideRadius = 6;
            offset = 15;
          }
          else {
            outsideRadius = 11;
            insideRadius = 8;
            offset = 20;
          }

          ctx.beginPath();
          ctx.fillStyle = '#FFFFFF';
          ctx.arc(centerImg.x + offset, centerImg.y - offset, outsideRadius, 0, 2 * Math.PI);
          ctx.fill();
          ctx.beginPath();
          ctx.fillStyle = stateMarkerColor;
          ctx.arc(centerImg.x + offset, centerImg.y - offset, insideRadius, 0, 2 * Math.PI);
          ctx.fill();
        }
      }
    }

    if (this.chargingGroupsInsideBoundingBox.length > 0 && this.map.getZoom() > 11) {
      this.chargingGroupsPixelPoints = [];
      ctx.globalAlpha = 1;
      for (let i = 0; i < this.chargingGroupsInsideBoundingBox.length; i++) {

        const centerImg = this.canvasLayer.map.latLngToContainerPoint(
          [this.chargingGroupsInsideBoundingBox[i].lat, this.chargingGroupsInsideBoundingBox[i].lon]);
        this.chargingGroupsPixelPoints.push(centerImg);

        const chargingGroupCoords = this.mapDrawerHelperService.getItemCoordOnCanvas(this.chargingGroupsInsideBoundingBox[i].powerid, this.map.getZoom(),
          this.settingsService.getMap().Skin);

        ctx.drawImage(this.mapDrawerHelperService.getOffscreenCanvas(), chargingGroupCoords[0], chargingGroupCoords[1], chargingGroupCoords[2], chargingGroupCoords[3],
          centerImg.x - (chargingGroupCoords[2] / 2), centerImg.y - (chargingGroupCoords[3] / 2), chargingGroupCoords[2], chargingGroupCoords[3]);
        let fontSize = 15;
        if (this.map.getZoom() < 14) {
          fontSize = 11;
        }
        else if (this.map.getZoom() < 15) {
          fontSize = 12;
        }
        else if (this.map.getZoom() < 17) {
          fontSize = 14;
        }
        else {
          fontSize = 16;
        }

        ctx.font = `500 ${fontSize}px Roboto`;
        ctx.fillStyle = "#fff";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(this.chargingGroupsInsideBoundingBox[i].count.toString(), centerImg.x, centerImg.y + 1);
      }
    }

    if (this.favoriteChargingStationsInsideBoundingBox.length > 0 && this.map.getZoom() > 7) {
      this.favoriteChargingStationPixelPoints = [];
      ctx.globalAlpha = 1;
      for (let i = 0; i < this.favoriteChargingStationsInsideBoundingBox.length; i++) {
        let imageId: string = this.favoriteChargingStationsInsideBoundingBox[i].img;
        let size: number;
        if (this.map.getZoom() < 15) {
          imageId += "_125";
          size = 40;
        }
        else if (this.map.getZoom() < 17) {
          imageId += "_150";
          size = 50;
        }
        else {
          imageId += "_200";
          size = 60;
        }
        if (this.settingsService.getMap().Skin === MapSkin.Light) {
          imageId += "_light";
        }
        const image = document.getElementById(imageId) as HTMLImageElement;
        const centerImg = this.canvasLayer.map.latLngToContainerPoint([this.favoriteChargingStationsInsideBoundingBox[i].lat, this.favoriteChargingStationsInsideBoundingBox[i].lon]);
        this.favoriteChargingStationPixelPoints.push(centerImg);
        ctx.drawImage(image, centerImg.x - (size / 2), centerImg.y - (size / 2), size, size);
      }
    }
  }

  private drawWindToCanvas(ctx: CanvasRenderingContext2D, windEl: WindElement, canvasEl: any): void {
    if (this.settingsService.getMap().Skin === MapSkin.Light) {
      ctx.fillStyle = 'rgba(130, 130, 130, ' + (windEl.Opacity / 255) + ')';
      ctx.strokeStyle = 'rgba(130, 130, 130, ' + (windEl.Opacity / 255) + ')';
    }
    else {
      ctx.fillStyle = 'rgba(204, 204, 204, ' + (windEl.Opacity / 255) + ')';
      ctx.strokeStyle = 'rgba(204, 204, 204, ' + (windEl.Opacity / 255) + ')';
    }

    if (canvasEl.bounds.contains([windEl.Location.lat, windEl.Location.lng]) && windEl.Speed != 0 && (windEl.Opacity != 0)) {
      const arrowCenterPoint = this.canvasLayer.map.latLngToContainerPoint([windEl.Location.lat, windEl.Location.lng]);
      ctx.save();

      let wind = Math.round(windEl.Speed);
      if (wind > 50) {
        wind = 50;
      }
      let height = wind / 2 + 15;

      let halfLength = height - 9;
      let dir = this.utilsService.toRad(windEl.Angle);
      let pointStart = this.utilsService.RotatePoint(0, -halfLength, dir);
      pointStart.x += arrowCenterPoint.x;
      pointStart.y += arrowCenterPoint.y;
      let pointEnd = this.utilsService.RotatePoint(0, +halfLength, dir);
      pointEnd.x += arrowCenterPoint.x;
      pointEnd.y += arrowCenterPoint.y;
      let pointArrow1 = this.utilsService.RotatePoint(0, -8, dir + (12 * 3.14159265358979323846 / 180));
      pointArrow1.x += pointEnd.x;
      pointArrow1.y += pointEnd.y;
      let pointArrow2 = this.utilsService.RotatePoint(0, -8, dir - (12 * 3.14159265358979323846 / 180));
      pointArrow2.x += pointEnd.x;
      pointArrow2.y += pointEnd.y;

      ctx.beginPath();
      ctx.moveTo(pointStart.x, pointStart.y);
      ctx.lineTo(pointEnd.x, pointEnd.y);
      ctx.fill();

      ctx.moveTo(pointArrow1.x, pointArrow1.y);
      ctx.lineTo(pointEnd.x, pointEnd.y);
      ctx.lineTo(pointArrow2.x, pointArrow2.y);
      ctx.stroke();
    }
  }

  private redrawCanvas(): void {
    this.getExtendedBoundingBox();
    this.mapService.setMapBounds(this.map.getBounds());
    this.canvasLayer.needRedraw();
  }

  lastTooltip = null;

  private canvasMouseClick(event: any): void {
    this.lastTooltip = null;
    if (!this.isCSTooltip(event) && !this.showOnMap && event.originalEvent.target.parentNode && !event.originalEvent.target.parentNode.classList.contains("plug")) {

      this.chargerPopupSubscriptions.forEach(element => {
        element.unsubscribe();
      });

      for (let i = 0; i < this.chargingStationsPixelPoints.length; i++) {
        if (this.utilsService.rectContains(event.containerPoint.x, event.containerPoint.y, this.chargingStationsPixelPoints[i].x, this.chargingStationsPixelPoints[i].y, this.getCanvasChargerSize(), this.getCanvasChargerSize())) {
          this.lastTooltip = { x: this.chargingStationsPixelPoints[i].x, y: this.chargingStationsPixelPoints[i].y };
          /** mobile vertically aligned popup */
          if (device.mobile()) {
            this.loadChargingStationPopup(PopupAlign.Top, 0, 20, this.chargingStationsInsideBoundingBox[i].id, true, true, false, true);
          }
          /** desktop horizontally aligned popup */
          else {
            let widthAnchor = this.map.getSize().x / 2 > this.chargingStationsPixelPoints[i].x ? -20 : 20;
            let popupAlign = this.map.getSize().x / 2 > this.chargingStationsPixelPoints[i].x ? PopupAlign.Right : PopupAlign.Left;

            this.loadChargingStationPopup(popupAlign, widthAnchor, 0, this.chargingStationsInsideBoundingBox[i].id, true, true, false, false);
          }

          break;
        }
      }

      for (let i = 0; i < this.favoriteChargingStationPixelPoints.length; i++) {
        if (this.utilsService.rectContains(event.containerPoint.x, event.containerPoint.y, this.favoriteChargingStationPixelPoints[i].x, this.favoriteChargingStationPixelPoints[i].y, this.getCanvasChargerSize(), this.getCanvasChargerSize())) {
          this.lastTooltip = { x: this.favoriteChargingStationPixelPoints[i].x, y: this.favoriteChargingStationPixelPoints[i].y };
          /** mobile vertically aligned popup */
          if (device.mobile()) {
            this.loadChargingStationPopup(PopupAlign.Top, 0, 24, this.favoriteChargingStationsInsideBoundingBox[i].id, true, true, false, true);
          }
          /** desktop horizontally aligned popup */
          else {
            let widthAnchor = this.map.getSize().x / 2 > this.favoriteChargingStationPixelPoints[i].x ? -23 : 23;
            let popupAlign = this.map.getSize().x / 2 > this.favoriteChargingStationPixelPoints[i].x ? PopupAlign.Right : PopupAlign.Left;

            this.loadChargingStationPopup(popupAlign, widthAnchor, 0, this.favoriteChargingStationsInsideBoundingBox[i].id, true, true, false, false);
          }

          break;
        }
      }

      // charging group zoom in
      for (let i = 0; i < this.chargingGroupsPixelPoints.length; i++) {
        if (this.utilsService.rectContains(event.containerPoint.x, event.containerPoint.y, this.chargingGroupsPixelPoints[i].x, this.chargingGroupsPixelPoints[i].y, this.getCanvasChargingGroupSize(), this.getCanvasChargingGroupSize())) {
          const chargerLatLng = this.canvasLayer.map.containerPointToLatLng(
            [this.chargingGroupsPixelPoints[i].x, this.chargingGroupsPixelPoints[i].y]);

          this.map.flyTo(chargerLatLng, this.map.getZoom() + 1);
        }
      }

    }
  }

  private getCanvasChargerSize(): number {
    return this.mapDrawerHelperService.getCanvasChargerSize(this.map.getZoom());
  }

  private getCanvasChargingGroupSize(): number {
    return this.mapDrawerHelperService.getCanvasChargerGroupSize(this.map.getZoom());
  }

  private changeStatusOpener(htmlEl: string): void {
    this.chargingStationRecalc = true;
    document.querySelector(".status.status-opener .status-icon").remove();
    document.querySelector(".status.status-opener .status-state").remove();
    const statusEL = document.createElement('div');
    statusEL.innerHTML = htmlEl;
    for (let i = statusEL.children.length - 1; i >= 0; i--) {
      document.querySelector(".status.status-opener").prepend(statusEL.children[i]);
    }
    if (this.accountService.getIsAuthorized()) {
      this.accountService.setDisabledChargingStations().subscribe();
      this.accountService.setFavoriteChargingStations().subscribe();
    }
    this.redrawCanvas();
    if (this.inputParamsService.getSelectedMode() == "route") {
      this.inputParamsService.paramsUpdate();
    }
    else {
      if (this.lastOpenedChargingStationPopup && !this.showOnMap) {
        this.redrawChargingStationPopup(this.lastOpenedChargingStationPopup);
      }
    }
  }

  private getChargerNormalStatusHtmlElements(): string {
    return this.mapDrawerHelperService.getChargerHtmlElements(this.settingsService.getMap().Skin, "normal",
      this.languageService.languageJSON.Global_Normal, null);
  }

  private getChargerNormalStatusHtmlElementsSelected(): string {
    return this.mapDrawerHelperService.getChargerHtmlElements(this.settingsService.getMap().Skin, "normal",
      this.languageService.languageJSON.Global_Normal, this.languageService.languageJSON.Map_Popup_Status);
  }

  private getChargerDisabledStatusHtmlElements(): string {
    return this.mapDrawerHelperService.getChargerHtmlElements(this.settingsService.getMap().Skin, "disabled",
      this.languageService.languageJSON.Map_Popup_Disabled, null);
  }

  private getChargerDisabledStatusHtmlElementsSelected(): string {
    return this.mapDrawerHelperService.getChargerHtmlElements(this.settingsService.getMap().Skin, "disabled",
      this.languageService.languageJSON.Map_Popup_Disabled, this.languageService.languageJSON.Map_Popup_Status);
  }

  private getChargerFavoriteStatusHtmlElements(): string {
    return this.mapDrawerHelperService.getChargerHtmlElements(this.settingsService.getMap().Skin, "favorite",
      this.languageService.languageJSON.Map_Popup_Favorite, null);
  }

  private getChargerFavoriteStatusHtmlElementsSelected(): string {
    return this.mapDrawerHelperService.getChargerHtmlElements(this.settingsService.getMap().Skin, "favorite",
      this.languageService.languageJSON.Map_Popup_Favorite, this.languageService.languageJSON.Map_Popup_Status);
  }

  // init charging stations
  public setChargingStations(): void {
    if (this.chargingStationsDetailed) {
      this.collectChargingStationsInsideBoundingBox();
    }
  }

  // adding charging stations tile layers to the map
  private setChargingStationsLayers(chargingStationsArray: boolean[]): void {
    const typeFlag = this.mapService.getTypeFlag();
    let url: string;

    if (!this.utilsService.isProductionUrl()) {
      url = 'https://staging.evnavigation.com/chargingstations/tiles/' + typeFlag + '/{z}_{x}_{y}_' + typeFlag + '.png?ver=' + this.tileVersion;
    } else {
      url = 'https://evnavigation.com/chargingstations/tiles/' + typeFlag + '/{z}_{x}_{y}_' + typeFlag + '.png?ver=' + this.tileVersion;
    }

    this.csLayer.setUrl(url);
  }

  // adding zoomed in mode charging station to map
  private collectChargingStationsInsideBoundingBox(): void {
    if (this.map.getZoom() <= 7) {
      return;
    }
    this.chargingStationsInsideBoundingBox = [];
    this.favoriteChargingStationsInsideBoundingBox = [];
    this.chargingStationsPixelPoints = [];
    this.favoriteChargingStationPixelPoints = [];
    this.chargingGroupsInsideBoundingBox = [];
    const selectedCS = this.mapService.getChargingStations();
    for (let i = 0; i < this.lastTileBounds.length; i++) {
      const chargingStationsArray = this.mapService.getTileChargingStations()[this.lastTileBounds[i]];
      for (let i = 0; i < chargingStationsArray?.length; i++) {
        if (this.lastBoundingBox.contains(new L.LatLng(chargingStationsArray[i].lat, chargingStationsArray[i].lon)) &&
          chargingStationsArray[i].types && this.isArrayContainsCS(selectedCS, chargingStationsArray[i].types) &&
          (this.settingsService.getContainsFavoriteChargingStationId(chargingStationsArray[i].id) || chargingStationsArray[i].minZoomLevel <= this.map.getZoom()) &&
          !this.isElementBigIcon(new L.LatLng(chargingStationsArray[i].lat, chargingStationsArray[i].lon))) {

          //recalculate charging station properties, only adding the selected charging stations to it
          const chargingStationEl = { ...chargingStationsArray[i] };

          const selectedPlugs = [];
          for (let j = 0; j < chargingStationEl.plugs.length; j++) {
            if (selectedCS[chargingStationEl.plugs[j].type - 1]) {
              selectedPlugs.push(chargingStationEl.plugs[j]);
            }
          }
          chargingStationEl.plugs = selectedPlugs;
          let maxpower = 0;
          for (let j = 0; j < chargingStationEl.plugs.length; j++) {
            if (chargingStationEl.plugs[j].power > maxpower) {
              maxpower = chargingStationEl.plugs[j].power;
            }
          }
          chargingStationEl.maxpower = maxpower;
          if (this.settingsService.getContainsFavoriteChargingStationId(chargingStationEl.id)) {
            if (chargingStationEl.maxpower > 35) {
              chargingStationEl.img = "cs_big3_fav";
            }
            else if (chargingStationEl.maxpower > 10) {
              chargingStationEl.img = "cs_big2_fav";
            }
            else {
              chargingStationEl.img = "cs_big1_fav";
            }
          }
          else if (this.settingsService.getContainsDisabledChargingStationId(chargingStationEl.id)) {
            chargingStationEl.img = "cs_dis";
          }
          else if (this.settingsService.getContainsDisabledChargingOperatorId(chargingStationEl.opid)) {
            chargingStationEl.img = "n_dis";
          }
          else {
            if (chargingStationEl.maxpower > 35) {
              chargingStationEl.img = "cs_big3";
            }
            else if (chargingStationEl.maxpower > 10) {
              chargingStationEl.img = "cs_big2";
            }
            else {
              chargingStationEl.img = "cs_big1";
            }
            if (this.settingsService.getFavoriteChargingOperatorIds().length > 0 &&
              !this.settingsService.getContainsFavoriteChargingOperatorId(chargingStationEl.opid)) {
              chargingStationEl.transparentOp = true;
            }
          }

          if (this.settingsService.getContainsFavoriteChargingStationId(chargingStationEl.id)) {
            this.favoriteChargingStationsInsideBoundingBox.push(chargingStationEl);
          }
          else {
            this.chargingStationsInsideBoundingBox.push(chargingStationEl);
          }
        }
      }

      if (this.mapService.getTileChargingGroups()[this.lastTileBounds[i]]?.hasOwnProperty(this.map.getZoom())) {
        const chargingGroupsArray = this.mapService.getTileChargingGroups()[this.lastTileBounds[i]][this.map.getZoom()];
        for (let i = 0; i < chargingGroupsArray?.length; i++) {
          if (this.lastBoundingBox.contains(new L.LatLng(chargingGroupsArray[i].lat, chargingGroupsArray[i].lon))) {
            const chargingGroupEl = { ...chargingGroupsArray[i] };

            this.chargingGroupsInsideBoundingBox.push(chargingGroupEl);
          }
        }
      }
    }
  }

  private isElementBigIcon(latlon: LatLng): boolean {
    for (let i = 0; i < this.bigChargingStations.length; i++) {
      if (this.bigChargingStations[i][0] == latlon.lat && this.bigChargingStations[i][1] == latlon.lng) {
        return true;
      }
    }
    return false;
  }

  // check for currently selected charging stations includes a charging station type
  private isArrayContainsCS(selectedCS: boolean[], stationTypes: string): boolean {
    let contains = false;
    let i = 0;
    while (!contains && i < selectedCS.length) {
      if (selectedCS[i] && stationTypes.includes((i + 1).toString())) {
        contains = true;
      }
      i++;
    }
    return contains;
  }

  public drawRoutePlanningAnimationToMap(waypoints: LatLng[]): void {
    this.polyLayer.clearLayers();
    this.markerLayer.clearLayers();
    this.chargingStationLayer.clearLayers();
    this.clearWind();
    let antPolyline = this.mapDrawerHelperService.getRoutePlanningAntPath(waypoints, this.settingsService.getMap().Skin);

    antPolyline.addTo(this.polyLayer);
    if (!this.chargingStationRecalc) {
      this.positionMapToRoute(waypoints);
      this.lastWaypoints = waypoints;
    }

  }

  // place the route of the center of the map
  private positionMapToRoute(waypoints: LatLng[]): void {
    const bounds: LatLngBounds = L.polygon(waypoints).getBounds();

    // mobile
    if (this.mobileResolutionService.getMobileResolution()) {
      // portrait
      if (!device.mobile() || window.innerHeight > window.innerWidth) {
        // slider panel opened
        if (this.showSliders) {
          const bottomHeight = (window.innerHeight <= 550) ? 200 : 320;
          this.map.fitBounds(bounds,
            {
              paddingTopLeft: [50, 50],
              paddingBottomRight: [50, 50 + bottomHeight]
            });
        }
        // slider panel closed
        else {
          const topHeight = (window.innerWidth <= 380) ? 260 : 308;
          this.map.fitBounds(bounds,
            {
              paddingTopLeft: [50, topHeight + 50],
              paddingBottomRight: [50, 50]
            });
        }
      }
      // landscape
      else {
        // slider panel opened
        if (this.showSliders) {
          const bottomHeight = (window.innerHeight <= 550) ? 200 : 320;
          this.map.fitBounds(bounds,
            {
              paddingTopLeft: [50, 50],
              paddingBottomRight: [50, 50 + bottomHeight]
            });
        }
        // slider panel closed
        else {
          const topHeight = (window.innerWidth <= 620) ? 53 : 64;
          this.map.fitBounds(bounds,
            {
              paddingTopLeft: [255 + 50, topHeight + 50],
              paddingBottomRight: [100, 50]
            });
        }
      }
    }
    // tablet, desktop
    else {
      this.map.fitBounds(bounds,
        {
          paddingTopLeft: [330, 330],
          paddingBottomRight: [330, 330]
        });
    }
  }

  public positionMapOnSlidersPanelOpen(): void {
    const mapCenterPoint: L.Point = this.map.project(this.map.getCenter(), this.map.getZoom());
    const height = this.map.getSize().y;
    const calculatedPoint = new Point(mapCenterPoint.x, mapCenterPoint.y + this.getMoveValue());

    this.map.panTo(this.map.unproject(calculatedPoint, this.map.getZoom()), { duration: 0.5 });
  }

  public positionMapOnSlidersPanelClose(): void {
    const moveValue = (window.innerHeight <= 550) ? 205 : 330;

    const mapCenterPoint: L.Point = this.map.project(this.map.getCenter(), this.map.getZoom());
    const height = this.map.getSize().y;
    const calculatedPoint = new Point(mapCenterPoint.x, mapCenterPoint.y - this.getMoveValue());

    this.map.panTo(this.map.unproject(calculatedPoint, this.map.getZoom()), { duration: 0.5 });
  }

  private getMoveValue(): number {
    let moveValue = 0;
    const portrait = window.innerHeight > window.innerWidth;

    if (portrait) {
      // used for slider panel opened
      const bottomHeight = (window.innerHeight <= 550) ? 200 : 320;
      // used slider panel closed
      const topHeight = (window.innerWidth <= 380) ? 260 : 308;
      moveValue += bottomHeight;
      if (this.inputParamsService.getSelectedMode() == "route") {
        moveValue += topHeight;
      }

      return moveValue / 2;
    }
    else {
      const bottomHeight = (window.innerHeight <= 550) ? 200 : 320;
      const topHeight = (window.innerWidth <= 620) ? 53 : 64;
      moveValue += bottomHeight;
      if (this.inputParamsService.getSelectedMode() == "route") {
        moveValue += topHeight;
      }

      return moveValue / 2;
    }
  }

  public clearWind(): void {
    if ((this.canvasLayer as any)._canvas) {
      this.windDatas = [];
      (this.canvasLayer as any)._canvas.getContext('2d').clearRect(0, 0, (this.canvasLayer as any)._canvas.width, (this.canvasLayer as any)._canvas.height);
    }
  }

  public drawOnePointRouteToMap(): void {
    this.polyLayer.clearLayers();
    this.markerLayer.clearLayers();
    this.clearWind();
    this.redrawCanvas();
    const startpoint = this.inputParamsService.getWaypointsParams();
    if (this.settingsService.getMap().Skin === MapSkin.Light) {
      L.marker(new L.LatLng(startpoint[0].lat, startpoint[0].lng), { interactive: false, icon: this.mapDrawerHelperService.startIconLight }).addTo(this.markerLayer);
    }
    else {
      L.marker(new L.LatLng(startpoint[0].lat, startpoint[0].lng), { interactive: false, icon: this.mapDrawerHelperService.startIcon }).addTo(this.markerLayer);
    }
  }

  // draw a failed route waypoints and beeline(s) to the map
  public drawFailedRouteToMap(maxReachedWaypoint): void {
    this.polyLayer.clearLayers();
    this.markerLayer.clearLayers();

    // set start end markers
    this.setStartEndMarkers(new LatLng(this.inputParamsService.getWaypointsParams()[0].lat,
      this.inputParamsService.getWaypointsParams()[0].lng),
      new LatLng(this.inputParamsService.getWaypointsParams()[this.inputParamsService.getWaypointsParams().length - 1].lat,
        this.inputParamsService.getWaypointsParams()[this.inputParamsService.getWaypointsParams().length - 1].lng)
      , true, false);

    // draw waypoints
    const waypointsCopy = [...this.inputParamsService.getWaypointsParams()];
    for (let i = 0; i < waypointsCopy.length - 1; i++) {
      // draw route beelines
      let antPolyline = this.mapDrawerHelperService.getFailedRouteAntPath([waypointsCopy[i], waypointsCopy[i + 1]], i < maxReachedWaypoint);

      antPolyline.addTo(this.polyLayer);
    }

    waypointsCopy.shift();
    waypointsCopy.pop();
    for (let i = 0; i < waypointsCopy.length; i++) {
      const routePointIcon = maxReachedWaypoint > i ? "route_point_waypoint.svg" : "route_point_waypoint_failed.svg";
      let waypointsDivIconHtml = "<div class='waypoint-icon-outer'><div class='waypoint-icon'>";
      waypointsDivIconHtml += `<img src='assets/icons/${this.settingsService.getMap().Skin}/map/${routePointIcon}'></div>`;
      waypointsDivIconHtml += `<div class='waypoint-idx'>${(i + 1)}</div></div>`;
      const divIcon = L.divIcon({ html: waypointsDivIconHtml, iconAnchor: [42, 42], iconSize: [84, 84] });
      L.marker(new L.LatLng(waypointsCopy[i].lat, waypointsCopy[i].lng), { interactive: false, icon: divIcon }).addTo(this.markerLayer);
    }

    this.lastRouteData = null;
  }

  // draw a planned route to map
  public drawRouteToMap(routeData: Route): void {
    // clear layers
    this.lastRouteData = { ...routeData };
    this.polyLayer.clearLayers();
    this.markerLayer.clearLayers();

    // waypoints
    this.mapService.getRoutePlanByType(routeData.RoutePlan, RoutePlanType.WayPoint).forEach((wayPointElement: RoutePlanElement) => {
      const waypointsDivIconHtml = `<div class='waypoint-icon-outer'><div class='waypoint-icon'>
        <img src='assets/icons/${this.settingsService.getMap().Skin}/map/route_point_waypoint.svg'></div>
        <div class='waypoint-idx'>${wayPointElement.WaypointIndex}</div></div>`;

      const divIcon = L.divIcon({ html: waypointsDivIconHtml, iconAnchor: [42, 42], iconSize: [84, 84] });
      L.marker(wayPointElement.Location, { icon: divIcon }).addTo(this.markerLayer).on('click', (e: any) => {
        this.mapService.ObservableActiveInfoSidebarRouteElement.next({ type: 'waypoint', index: wayPointElement.WaypointIndex - 1 });
      });

      // waypoint marker battery reserved warning icon
      if (wayPointElement.BatteryReservedWarning) {
        this.drawBatteryReservedWarningForIcon(wayPointElement.Location);
      }
    });
    // add start end icon, route polyline
    this.setStartEndMarkers(this.mapService.getRoutePlanStartPoint(routeData.RoutePlan).Location
      , this.mapService.getRoutePlanEndPoint(routeData.RoutePlan).Location, false, false);

    // endmarker battery marker
    const remainingBatteryHtml = `<div class='charge-time'><div class='charge-time-inner'> 
    <img class='remained-battery' src='assets/icons/dark/ui/chargingplan_time_battery.svg'/><h4>
    ${this.mapService.getRoutePlanEndPoint(routeData.RoutePlan).BatteryLevel}%</h4> <h4></div></div>`;
    const divIcon = L.divIcon({ html: remainingBatteryHtml, iconAnchor: [33, 70], iconSize: [66, 21] });
    L.marker(this.mapService.getRoutePlanEndPoint(routeData.RoutePlan).Location, { interactive: false, icon: divIcon }).addTo(this.markerLayer);

    // end marker battery reserved warning icon
    if (this.mapService.getRoutePlanEndPoint(routeData.RoutePlan).BatteryReservedWarning) {
      this.drawBatteryReservedWarningForIcon(this.mapService.getRoutePlanEndPoint(routeData.RoutePlan).Location);
    }

    // grey outline - light mode
    if (this.settingsService.getMap().Skin === MapSkin.Light) {
      for (let i = 1; i < routeData.RoutePoints.length; i++) {
        L.polyline([routeData.RoutePoints[i - 1].Location, routeData.RoutePoints[i].Location], { color: "#ccc", weight: 15 }).addTo(this.polyLayer);
      }
    }

    // white line
    for (let i = 1; i < routeData.RoutePoints.length; i++) {
      L.polyline([routeData.RoutePoints[i - 1].Location, routeData.RoutePoints[i].Location], { color: "#fff", weight: 13 }).addTo(this.polyLayer);
    }

    // colored line
    for (let i = 1; i < routeData.RoutePoints.length; i++) {
      L.polyline([routeData.RoutePoints[i - 1].Location, routeData.RoutePoints[i].Location],
        { color: this.utilsService.getColorStringForJamFactor(routeData.RoutePoints[i - 1].JamFactor), weight: 7 }).addTo(this.polyLayer);
    }

    this.bigChargingStations = [];

    const singleAndMultipleChargingStations = this.mapService.getSingleAndMultipleChargingStations(routeData.RoutePlan);

    // draw single stop charging stations
    singleAndMultipleChargingStations.SingleChargingStations.forEach((charger: RoutePlanElement) => {

      // selected charging statinos database
      this.bigChargingStations.push(charger.Location);

      // chargining station divicon
      let chargingStationDivIconHtml = "<div class='map-charger'>";
      // charge time box
      let chargeTimeMin: any = charger.ChargeTimeMin;
      let chargeTimeHour = charger.ChargeTimeHour;
      if (chargeTimeMin.toString().length < 2) {
        chargeTimeMin = "0" + chargeTimeMin.toString();
      }
      if (chargeTimeHour > 0) {
        if (chargeTimeMin == "00") {
          chargingStationDivIconHtml += `<div class='charge-time'><div class='charge-time-inner'> <h4>${chargeTimeHour}</h4> <small>${this.languageService.languageJSON.Unit_Hour_Short}</small> <h4></div></div>`;
        }
        else {
          chargingStationDivIconHtml += `<div class='charge-time'><div class='charge-time-inner'> <h4>${chargeTimeHour}</h4> <small>${this.languageService.languageJSON.Unit_Hour_Short}</small> <h4>${chargeTimeMin}</h4><small>${this.languageService.languageJSON.Unit_Minute_Short}</small></div></div>`;
        }
      }
      else {
        chargingStationDivIconHtml += `<div class='charge-time'><div class='charge-time-inner'> <h4>${chargeTimeMin}</h4><small>${this.languageService.languageJSON.Unit_Minute_Short}</small></div></div>`;
      }

      let iconSvg;
      // charge icon
      if (charger.ChargePower > 35) {
        iconSvg = "charging_station_3";
      }
      else if (charger.ChargePower > 11) {
        iconSvg = "charging_station_1";
      }
      else {
        iconSvg = "charging_station_2";
      }
      chargingStationDivIconHtml += `<div class="charge-icon">
      <img src="assets/icons/${this.settingsService.getMap().Skin}/map/${iconSvg}.svg"/></div>
      <div class="charge-index">
      <img src="assets/icons/default/map/chargingstation_icon.svg"/>${(charger.ChargerIndex + 1)}</div>`;

      if (this.settingsService.getContainsFavoriteChargingStationId(charger.ChargingStationId)) {
        chargingStationDivIconHtml += `<div class="favorite-icon"><img src="assets/icons/default/map/favorite_icon_planning.svg"/></div>`;
      }

      chargingStationDivIconHtml += "</div>";

      let divIcon = L.divIcon({ html: chargingStationDivIconHtml, iconAnchor: [42, 66], iconSize: [84, 108] });
      let marker = L.marker(charger.Location, { icon: divIcon }).addTo(this.markerLayer).on('click', (e: any) => {
        if (!this.showOnMap) {
          this.mapService.getChargingStationDetail([charger.ChargingStationId]).subscribe((resp) => {
            if (resp) {
              this.chargingStationLayer.clearLayers();
              // vertically aligned popup above planned route charger
              this.drawChargingStationPopup(PopupAlign.Top, 0, 80, resp[0], true, false, false, true);
            }
          });
        }
      });
      marker.on('click', (e: any) => {
        this.mapService.ObservableActiveInfoSidebarRouteElement.next({ type: 'charger', index: charger.ChargerIndex });
      });

      if (charger.BatteryReservedWarning) {
        this.drawBatteryReservedWarningForIcon(charger.Location);
      }
    });

    // draw multiple stop charging stations
    singleAndMultipleChargingStations.MultiChargingStations.forEach((multipleCharger: RoutePlanElement[]) => {
      // selected charging stations database
      this.bigChargingStations.push(multipleCharger[0].Location);

      // chargining station divicon
      let chargingStationDivIconHtml = "<div class='map-charger multiple-stop'><div class='charge-time'>";

      // charge time box
      multipleCharger.forEach((charger: RoutePlanElement) => {
        chargingStationDivIconHtml += `<div class='charge-time-inner'><span class='multi-charge-index'>
        <img class='charge-index-icon' src='assets/icons/default/map/chargingstation_icon.svg'/>${charger.ChargerIndex + 1}</span>`;
        let chargeTimeMin: any = charger.ChargeTimeMin;
        let chargeTimeHour = charger.ChargeTimeHour;
        if (chargeTimeMin.toString().length < 2) {
          chargeTimeMin = "0" + chargeTimeMin.toString();
        }
        if (chargeTimeHour > 0) {
          if (chargeTimeMin == "00") {
            chargingStationDivIconHtml += `<h4>${chargeTimeHour}</h4> <small>${this.languageService.languageJSON.Unit_Hour_Short}</small> <h4></div>`;
          }
          else {
            chargingStationDivIconHtml += `<h4>${chargeTimeHour}</h4> <small>${this.languageService.languageJSON.Unit_Hour_Short}</small> <h4>${chargeTimeMin}</h4><small>${this.languageService.languageJSON.Unit_Minute_Short}</small></div>`;
          }
        }
        else {
          chargingStationDivIconHtml += `<h4>${chargeTimeMin}</h4><small>${this.languageService.languageJSON.Unit_Minute_Short}</small></div>`;
        }
      });

      let iconSvg;
      // charge icon
      if (multipleCharger[0].ChargePower > 35) {
        iconSvg = "charging_station_3";
      }
      else if (multipleCharger[0].ChargePower > 11) {
        iconSvg = "charging_station_1";
      }
      else {
        iconSvg = "charging_station_2";
      }

      chargingStationDivIconHtml += "</div><div class='charge-icon'><img src='assets/icons/" + this.settingsService.getMap().Skin + "/map/" + iconSvg + ".svg  '/></div><div class='charge-index'><img src='assets/icons/default/map/chargingstation_icon.svg'/></div></div>";

      let divIcon = L.divIcon({ html: chargingStationDivIconHtml, iconAnchor: [42, 47 + (19 * multipleCharger.length)], iconSize: [84, 108] });
      let marker = L.marker(multipleCharger[0].Location, { icon: divIcon }).addTo(this.markerLayer).on('click', (e: any) => {
        if (!this.showOnMap) {
          this.mapService.getChargingStationDetail([multipleCharger[0].ChargingStationId]).subscribe((resp) => {
            if (resp) {
              this.chargingStationLayer.clearLayers();
              // vertically aligned popup above planned route multiple stop charger
              this.drawChargingStationPopup(PopupAlign.Top, 0, 61 + (19 * multipleCharger.length), resp[0], true, false, false, true);
            }
          });
        }
      });
      marker.on('click', (e: any) => {
        this.mapService.ObservableActiveInfoSidebarRouteElement.next({ type: 'charger', index: multipleCharger[0].ChargerIndex });
      });

      if (multipleCharger[0].BatteryReservedWarning) {
        this.drawBatteryReservedWarningForIcon(multipleCharger[0].Location);
      }
    });

    // Road Surface Alerts
    routeData.RoadSurfaceAlerts.forEach(alert => {
      const alertIcon = this.mapDrawerHelperService.getRoadSurfaceAlertIcon(alert, this.settingsService.getMap().Skin);
      const divIconAlert = L.divIcon({
        html: alertIcon.iconHtml,
        iconAnchor: [12, 12],
        iconSize: [24, 24]
      });

      // Create a marker for the alert and add it to the marker layer
      L.marker(alert.Location, { icon: divIconAlert, zIndexOffset: -800 })
        .addTo(this.markerLayer)
        .bindTooltip(alertIcon.tooltip, {
          direction: 'top',   // this will position the tooltip above the marker
          offset: L.point(0, -10), // sets how far from the marker the tooltip should be
          opacity: 1.0,       // sets the opacity of the tooltip for visibility
          className: "context-tooltip" // additional styling class for tooltip
        });
    });

    // Draw Incidents
    routeData.Incidents.forEach(incident => {
      const incidentIcon = this.mapDrawerHelperService.getIncidentIcon(incident, this.settingsService.getMap().Skin);
      const divIconIncident = L.divIcon({
        html: incidentIcon.iconHtml,
        iconAnchor: [12, 12], // sets the point of the icon which will correspond to marker's location
        iconSize: [24, 24]   // optionally set size of the icon
      });

      // Create a marker for the incident and add it to the marker layer
      L.marker(incident.Location, { icon: divIconIncident, zIndexOffset: -1000 })
        .addTo(this.markerLayer)
        .bindTooltip(incidentIcon.tooltip, {
          direction: 'top',   // this will position the tooltip above the marker
          offset: L.point(0, -10), // sets how far from the marker the tooltip should be
          opacity: 1.0,       // sets the opacity of the tooltip for visibility
          className: "context-tooltip" // additional styling class for tooltip
        });
    });

    routeData.RouteEVSpeedLimitSegments.forEach((evSpeedLimitSegment) => {
      if (evSpeedLimitSegment.SpeedLimit < 130 && evSpeedLimitSegment.SpeedLimit > 0) {

        let speedLimitHtml = `<div class='speedlimit-outer'><div class='speedlimit'>
        <img class='speedlimit-icon' src='assets/icons/light/ui/slider_small_button_battery.svg'/>
        <p class='speedlimit-value'>
        ${this.settingsService.getUnit().Speed == Unit.Metric ? evSpeedLimitSegment.SpeedLimit : Math.round(this.utilsService.kmphToMph(evSpeedLimitSegment.SpeedLimit))}
        </p></div></div>`;

        // tooltip speed limit
        const tooltip: string = this.languageService.languageJSON.Map_Tooltip_MaxSpeed.toString();

        const divIconSpeed = L.divIcon({ html: speedLimitHtml, iconAnchor: [20, 20], iconSize: [40, 40] });
        L.marker(routeData.RoutePoints[evSpeedLimitSegment.ShowInfoAtRouteIndex].Location, { icon: divIconSpeed }).addTo(this.markerLayer).bindTooltip(tooltip, {
          direction: 'top',
          offset: L.point(0, -10),
          opacity: 1.0,
          className: "context-tooltip"
        });
      }

      if (this.inputParamsService.getSearchedRangeOrRoute()) {
        this.inputParamsService.setSearchedRangeOrRoute(false);
      }
    });
  }

  private drawBatteryReservedWarningForIcon(coordinate: L.LatLng): void {
    L.marker(coordinate, { icon: this.mapDrawerHelperService.drawBatteryReservedWarningForIcon() }).addTo(this.markerLayer).on('click', (ev) => {
      this.snackBar.open(this.languageService.languageJSON.WarningSoc_popup, "", { duration: 5000, panelClass: 'snackbar-warning' });
    });
  }

  private drawTripToMap(tripPoints: TripPoint[]): void {
    this.polyLayer.clearLayers();
    this.markerLayer.clearLayers();
    this.activeTripLayer.clearLayers();

    /*let angle = 0;
    if (tripPoints.length > 1) {
      angle = this.utilsService.angleFromCoordinate(tripPoints[tripPoints.length - 2][0], tripPoints[tripPoints.length - 2][1], tripPoints[tripPoints.length - 1][0], tripPoints[tripPoints.length - 1][1]);
    }
    this.setMarker(new LatLng(tripPoints[tripPoints.length - 1][0], tripPoints[tripPoints.length - 1][1]), angle);*/
    const indexes = tripPoints.map((el, index) => { return index });
    L.polyline(tripPoints, { color: "#fff", weight: 14 }).addTo(this.activeTripLayer);

    const waypoints = tripPoints.map(el => { return [el.lat, el.lon] });

    // add start end icon, route polyline
    this.setStartEndMarkers(new L.LatLng(tripPoints[0].lat, tripPoints[0].lon), new L.LatLng(tripPoints[tripPoints.length - 1].lat, tripPoints[tripPoints.length - 1].lon), false, true);

    // grey outline - light mode
    if (this.settingsService.getMap().Skin === MapSkin.Light) {
      for (let i = 1; i < tripPoints.length; i++) {
        L.polyline([[tripPoints[i - 1].lat, tripPoints[i - 1].lon], [tripPoints[i].lat, tripPoints[i].lon]], { color: "#ccc", weight: 15 }).addTo(this.polyLayer)
      }
    }

    // white line
    for (let i = 1; i < tripPoints.length; i++) {
      L.polyline([[tripPoints[i - 1].lat, tripPoints[i - 1].lon], [tripPoints[i].lat, tripPoints[i].lon]], { color: "#fff", weight: 13 }).addTo(this.polyLayer);
    }

    // colored line
    for (let i = 1; i < tripPoints.length; i++) {
      L.polyline([[tripPoints[i - 1].lat, tripPoints[i - 1].lon], [tripPoints[i].lat, tripPoints[i].lon]],
        { color: this.utilsService.rgbToHex((255 - tripPoints[i].soc * 255), ((tripPoints[i].soc * 255)), 0), weight: 7 })
        .addTo(this.polyLayer);
    }

    // tooltip (optimized for mobile)
    if (this.settingsService.getMap().Skin === MapSkin.Light) {
      for (let i = 1; i < tripPoints.length; i++) {
        L.polyline([[tripPoints[i - 1].lat, tripPoints[i - 1].lon], [tripPoints[i].lat, tripPoints[i].lon]], { color: "#fff", weight: 18, opacity: 0 }).addTo(this.polyLayer)
          .bindTooltip(this.languageService.languageJSON.SliderSidebar_BatteryLevel + ": " + Math.round(tripPoints[i].soc * 100).toString() + "%");
      }
    }

    /*const currArrows = this.mapService.generateTrackArrows(tripPoints, indexes, LOD_MULTIPLY[this.lodCurrent]);
    this.consoleLoggerService.log("CURR ARROWS", currArrows);
    for (let j = 0; j < currArrows.length; j++) {
      currArrows[j].addTo(this.activeTripLayer);
    };*/
    this.positionMapToRoute(tripPoints.map(el => { return new LatLng(el.lat, el.lon) }));
  }

  private drawAddressMarkerToMap(address: Address): void {
    const addressIcon = address.AddressType == AddressType.Home ? "home_icon.svg" : "work_icon.svg";
    const addressText = address.AddressType == AddressType.Home ? this.languageService.languageJSON.SettingsDialog_Home : this.languageService.languageJSON.SettingsDialog_Work;
    const addressMarkerHtml = `<div class='address-type'>${addressText}</div>
    <div class='home-icon'>
    <div class='circle'></div>
    <img class='map-point' src='assets/icons/light/map/mappoint.svg'/>
    <img class='map-point-image' src='assets/icons/dark/ui/${addressIcon}'> </div>`;
    L.marker(address.Location, { interactive: false, icon: L.divIcon({ html: addressMarkerHtml, iconAnchor: [23, 68], iconSize: [46, 68] }) }).addTo(this.searchLayer);
  }

  // getting extneded bounds for drawing to map
  private getExtendedBoundingBox(): void {
    const SW = this.map.getBounds().getSouthWest();
    const NE = this.map.getBounds().getNorthEast();
    const largerBoundsSW = new L.LatLng(SW.lat - (NE.lat - SW.lat) / 2, SW.lng - (NE.lng - SW.lng) / 2);
    const largerBoundsNE = new L.LatLng(NE.lat + (NE.lat - SW.lat) / 2, NE.lng + (NE.lng - SW.lng) / 2);
    const largerBounds = new L.LatLngBounds(largerBoundsSW, largerBoundsNE);
    this.lastBoundingBox = largerBounds;

    // download charging station tiles
    if (this.favoriteChargingStationsDetailed) {
      const neTile = this.utilsService.LatLonToTile(largerBoundsNE.lat, largerBoundsNE.lng, 256, 128);
      const swTile = this.utilsService.LatLonToTile(largerBoundsSW.lat, largerBoundsSW.lng, 256, 128);
      const minX = Math.min(swTile[0], neTile[0]);
      const maxX = Math.max(swTile[0], neTile[0]);
      const minY = Math.min(swTile[1], neTile[1]);
      const maxY = Math.max(swTile[1], neTile[1]);

      this.lastTileBounds = [];

      for (let xIndex = minX; xIndex <= maxX; xIndex++) {
        for (let yIndex = minY; yIndex <= maxY; yIndex++) {
          this.lastTileBounds.push(this.mapService.getTileId(xIndex, yIndex));
          if (0 <= xIndex && xIndex <= 255 && 0 <= yIndex && yIndex <= 127 && !this.mapService.hasTileDownloaded(xIndex, yIndex)) {
            this.mapService.getChargingStationTile(xIndex, yIndex).subscribe((resp) => {
              this.collectChargingStationsInsideBoundingBox();
              this.canvasLayer.needRedraw();
            });
          }
        }
      }

      const minLat = Math.min(largerBoundsNE.lat, largerBoundsSW.lat);
      const maxLat = Math.max(largerBoundsNE.lat, largerBoundsSW.lat);
      const minLon = Math.min(largerBoundsNE.lng, largerBoundsSW.lng);
      const maxLon = Math.max(largerBoundsNE.lng, largerBoundsSW.lng);

      if (this.accountService.getUserSubscription() != SubscriptionType.Free) {
        this.chargerStatusTimer.unsubscribe();

        this.chargerStatusTimer = timer(2000, 60000).subscribe(() => {
          this.mapService.getChargingStationListStatus(minLat, maxLat, minLon, maxLon, this.mapService.getSelectedCar().ChargerTypes).subscribe((resp) => {
            this.chargingStationBoundStatus = resp;
            if (this.canvasLayer.map?.getSize()) {
              this.canvasLayer.needRedraw();
            }
          });
        });
      }
    }

    if (this.chargingStationsDetailed || this.favoriteChargingStationsDetailed) {
      this.collectChargingStationsInsideBoundingBox();
    }
  }

  // fit map to the searched location
  private navigateToLocation(searchEl: SearchedRouteElement): void {
    this.map.fitBounds(L.latLngBounds(
      [L.latLng(searchEl.boundingbox[0], searchEl.boundingbox[1]),
      L.latLng(searchEl.boundingbox[2], searchEl.boundingbox[3])]
    ));
    this.searchLayer.clearLayers();
    L.marker([searchEl.lat, searchEl.lon], { interactive: false, icon: L.divIcon({ html: "<div class='pulse'></div>", iconAnchor: [11, 11], iconSize: [22, 22] }) }).addTo(this.searchLayer);
    clearTimeout(this.pulseInterval);
    this.pulseInterval = setTimeout(() => this.searchLayer.clearLayers(), 3000);
  }

  private isCSTooltip(e): boolean {
    const path = e.originalEvent.composedPath();
    if (path) {
      for (let i = 0; i < path.length; i++) {
        if (path[i].className && typeof path[i].className === "string" && path[i].className.includes("cs-tooltip")) {
          return true;
        }
      }
    }

    return false;
  }

  private closeCSTooltip() {
    this.chargingStationLayer.clearLayers();
    this.lastOpenedChargingStationPopup = null;
  }

  private loadChargingStationPopup(align: PopupAlign, widthAnchor: number, heightAnchor: number, chargingStationId: string,
    statusSelector: boolean, planBtn: boolean, searched: boolean, flyTo: boolean) {
    this.mapService.getChargingStationDetail([chargingStationId]).subscribe((resp) => {
      this.drawChargingStationPopup(align, widthAnchor, heightAnchor, resp[0],
        statusSelector, planBtn, searched, flyTo);
    });
  }

  private drawChargingStationPopup(align: PopupAlign, widthAnchor: number, heightAnchor: number, chargingStation: ChargingStationDetails,
    statusSelector: boolean, planBtn: boolean, searched: boolean, flyTo: boolean): void {
    this.lastOpenedChargingStationPopup = chargingStation;

    if (device.mobile() && flyTo) {
      const mapCenterPoint: L.Point = this.map.project(new LatLng(chargingStation.lat, chargingStation.lon), this.map.getZoom());
      const height = this.map.getSize().y;
      const calculatedPoint = new Point(mapCenterPoint.x, mapCenterPoint.y - height * 0.4);

      if (!this.mobileResolutionService.isPortrait() && this.inputParamsService.getSelectedMode() == "route") {
        calculatedPoint.x = calculatedPoint.x - 125;
      }

      this.map.panTo(this.map.unproject(calculatedPoint, this.map.getZoom()), { duration: 0.5, easeLinearity: 0.5 });
    }

    let tooltip = "";

    switch (align) {
      case PopupAlign.Left:
        tooltip += "<div class='map-tooltip arrow-to-right cs-tooltip' style='margin-left:-100%'>";
        break;
      case PopupAlign.Right:
        tooltip += "<div class='map-tooltip arrow-to-left cs-tooltip'>";
        break;
      case PopupAlign.Top:
        if (planBtn || searched) {
          tooltip += "<div class='map-tooltip arrow-to-bottom top cs-tooltip'>";
        }
        else {
          tooltip += "<div class='map-tooltip arrow-to-bottom top rounded-body cs-tooltip'>";
        }
        break;
    }

    tooltip += `<div class="title"><h3>${chargingStation.title}</h3></div>`;
    tooltip += `<div class="body"><div class="info">`;
    if (chargingStation.opid != undefined && chargingStation.opid != null && chargingStation.opid != -1) {
      tooltip += `<h4>${this.mapService.getChargingOperatorByID(chargingStation.opid).name}</h4>`;
    }
    if (chargingStation.opid != undefined && chargingStation.opid != null && chargingStation.opid != -1 && this.mapService.getChargingOperatorByID(chargingStation.opid).url) {
      tooltip += `<div class="info-el">
            <img class="info-image" src="assets/icons/${this.settingsService.getMap().Skin}/map/info_icon_web.svg" />
            <a target="_blank" href="${this.mapService.getChargingOperatorByID(chargingStation.opid).url}">${this.mapService.getChargingOperatorByID(chargingStation.opid).url}</a></div>`;
    }
    if (chargingStation.phone) {
      tooltip += `<div class="info-el">
            <img class="info-image" src="assets/icons/${this.settingsService.getMap().Skin}/map/info_icon_phone.svg" />
            <a target="_blank" href="tel:${chargingStation.phone}">${chargingStation.phone}</a></div>`;
    }
    if (chargingStation.cost) {
      if (this.utilsService.validURL(chargingStation.cost)) {
        tooltip += `<div class="info-el">
            <img class="info-image" src="assets/icons/${this.settingsService.getMap().Skin}/map/info_icon_price.svg" />
            <a target="_blank" href="${chargingStation.cost}">${chargingStation.cost}</a></div>`;
      }
      else {
        tooltip += `<div class="info-el">
            <img class="info-image" src="assets/icons/${this.settingsService.getMap().Skin}/map/info_icon_price.svg" />
            <span class="info-el-text">${chargingStation.cost}</span></div>`;
      }
    }

    if (chargingStation.provider = ChargerProvider.OCM) {
      const ocmId = this.utilsService.getOCMId(chargingStation.id);
      tooltip += `<div class="info-el">
          <img class="info-image" src="assets/icons/default/map/info_icon_ocm.svg" />
          <a target="_blank" href="https://openchargemap.org/site/poi/details/${ocmId}">${this.languageService.languageJSON.Map_Popup_MoreInfo}</a></div>`;
    }
    tooltip += `</div>`;

    for (let j = 0; j < chargingStation.plugs.length; j++) {
      let chargePower = Math.round(chargingStation.plugs[j].power * 10) / 10;
      let chargePowerText = "";

      let chargerType = chargingStation.plugs[j].type;

      let carPower = chargerType == 3 || chargerType == 5 || chargerType == 6 ? this.mapService.getSelectedCar().ChargePower : this.mapService.getSelectedCar().FastChargePower;

      if ((carPower > 0) && (carPower < chargePower)) {
        chargePowerText = chargePower + "kW (" + carPower + " kW)";
      }
      else {
        chargePowerText = chargePower + " kW";
      }
      tooltip += `<div class="plug ${j == chargingStation.plugs.length - 1 ? 'last-plug' : ''} ${chargingStation.plugs[j].available != undefined ? 'availability-info' : ''}">
              <img src="/assets/icons/${this.settingsService.getMap().Skin}/plug/${this.utilsService.chargingstationsImg[chargingStation.plugs[j].type - 1][0]}"/>`;
      if (chargingStation.plugs[j].available != undefined) {
        tooltip += `<div class="availabity ${chargingStation.plugs[j].available > 0 ? 'available' : 'not-available'}">${chargingStation.plugs[j].available}/${chargingStation.plugs[j].count}</div>`;
      }
      tooltip += `<p> ${chargingStation.plugs[j].count} x ${this.utilsService.chargingstationsImg[chargingStation.plugs[j].type - 1][1]}
                | ${chargePowerText}</p></div>`;
    }

    if (statusSelector) {
      tooltip += `<div class="status status-opener">`;

      if (this.settingsService.getContainsDisabledChargingStationId(chargingStation.id)) {
        tooltip += this.getChargerDisabledStatusHtmlElementsSelected();
      }
      else if (this.settingsService.getContainsFavoriteChargingStationId(chargingStation.id)) {
        tooltip += this.getChargerFavoriteStatusHtmlElementsSelected();
      }
      else {
        tooltip += this.getChargerNormalStatusHtmlElementsSelected();
      }

      tooltip += `<img class="status-opener-icon" src="assets/icons/${this.settingsService.getMap().Skin}/ui/open_arrow.svg" />
          </div>`;

      tooltip += `<div class="status-dropdown">
            <div class="status" id="normal-btn">${this.getChargerNormalStatusHtmlElements()}</div>
            <div class="divider"></div>
            <div class="status" id="favorite-btn">${this.getChargerFavoriteStatusHtmlElements()}</div>
            <div class="divider"></div>
            <div class="status" id="disabled-btn">${this.getChargerDisabledStatusHtmlElements()}</div>
          </div>`;
    }
    tooltip += `</div>`;
    if (planBtn) {
      if (this.inputParamsService.getWaypointsParams().length >= 5) {
        tooltip += "<div class='plan-btn inactive'>" + this.languageService.languageJSON.Map_Popup_ChargeHere + "</div>";
      }
      else {
        tooltip += "<div class='plan-btn'>" + this.languageService.languageJSON.Map_Popup_ChargeHere + "</div>";
      }
    }
    if (searched) {
      tooltip += "<div class='plan-btn plan-btn-container'></div>";
    }
    tooltip += "</div>";

    const divIcon = L.divIcon({ html: tooltip, iconAnchor: [widthAnchor, heightAnchor] });

    const marker = L.marker([chargingStation.lat, chargingStation.lon], { interactive: false, icon: divIcon, zIndexOffset: 1000 });
    if (searched) {
      marker.addTo(this.searchLayer);
    }
    else {
      marker.addTo(this.chargingStationLayer);
    }

    if (planBtn) {
      if (this.inputParamsService.getWaypointsParams().length >= 5) {
        this.snackBar.open(this.languageService.languageJSON.InfoSidebar_WaypointLimitReached, "", { duration: 5000, panelClass: 'snackbar-info' });
      }

      this.chargerPopupSubscriptions.push(fromEvent(document.getElementsByClassName('plan-btn')[0], 'click').pipe(first()).subscribe((event) => {
        if (event.isTrusted) {
          this.inputParamsService.ObservableReverseGeocodingLoader.next(true);
          this.mapService.getReverseGeocode(chargingStation.lat, chargingStation.lon).subscribe((reversegeocode) => {
            if (reversegeocode) {
              this.inputParamsService.addRoutePoint({
                display_name: reversegeocode,
                lat: chargingStation.lat,
                lng: chargingStation.lon,
                csid: chargingStation.id
              }, this.getChargeHereClosestRouteIndex(chargingStation));
            }
          }, () => { });
        }
      }));
    }

    if (statusSelector) {
      this.chargerPopupSubscriptions.push(fromEvent(document.getElementsByClassName('status-opener')[0], 'click').subscribe((event) => {
        if (event.isTrusted) {
          document.getElementsByClassName('status-dropdown')[0].classList.add("show");
        }
      }));

      this.chargerPopupSubscriptions.push(fromEvent(document.getElementsByClassName('status-dropdown')[0], 'click').subscribe((event) => {
        if (event.isTrusted) {
          document.getElementsByClassName('status-dropdown')[0].classList.remove("show");
        }
      }));

      this.chargerPopupSubscriptions.push(fromEvent(document.getElementsByClassName('cs-tooltip')[0], 'click').subscribe((event: any) => {
        if (event.isTrusted) {
          let removeClass: boolean = true;
          const path = event.composedPath();
          if (path) {
            for (let i = 0; i < path.length; i++) {
              if (path[i].className && path[i].className?.includes("status status-opener") ||
                path[i].className && path[i].className?.includes("status-dropdown")) {
                removeClass = false;
                break;
              }
            }
          }
          if (removeClass) {
            document.getElementsByClassName('status-dropdown')[0].classList.remove("show");
          }
        }
      }));

      this.chargerPopupSubscriptions.push(fromEvent(document.getElementById('normal-btn'), 'click').subscribe((event) => {
        if (event.isTrusted) {
          this.settingsService.setToNormalChargingStation(chargingStation.id);
          this.changeStatusOpener(this.getChargerNormalStatusHtmlElementsSelected());
        }
      }));

      this.chargerPopupSubscriptions.push(fromEvent(document.getElementById('favorite-btn'), 'click').subscribe((event) => {
        if (event.isTrusted) {
          this.settingsService.setToFavoriteChargingStation(chargingStation.id);
          this.changeStatusOpener(this.getChargerFavoriteStatusHtmlElementsSelected());
        }
      }));

      this.chargerPopupSubscriptions.push(fromEvent(document.getElementById('disabled-btn'), 'click').subscribe((event) => {
        if (event.isTrusted) {
          this.settingsService.setToDisabledChargingStation(chargingStation.id);
          this.changeStatusOpener(this.getChargerDisabledStatusHtmlElementsSelected());
        }
      }));
    }
  }

  // predict charger here best position on route, by adding next to the closest route point
  private getChargeHereClosestRouteIndex(chargingStation: ChargingStationDetails): number {
    let closestRoutePointIdx = -1;
    let closestRoutePointDistance = -1;
    if (this.inputParamsService.getSelectedMode() == "rha") {
      return this.inputParamsService.getWaypointsParams().length;
    }

    if (this.lastRouteData) {
      for (let i = 0; i < this.lastRouteData.RoutePoints.length; i++) {
        let actDist = this.utilsService.distanceBetweenCoordinates([chargingStation.lat, chargingStation.lon],
          [this.lastRouteData.RoutePoints[i].Location.lat, this.lastRouteData.RoutePoints[i].Location.lng]);
        if (actDist < 30 * 1000 && (closestRoutePointDistance == -1 || actDist < closestRoutePointDistance)) {
          closestRoutePointIdx = i;
          closestRoutePointDistance = actDist;
        }
      }

      if (closestRoutePointIdx != -1) {
        let i = 0;
        let reachedWaypoints = 0;
        while (this.lastRouteData.Turns[i] && this.lastRouteData.Turns[i].RoutePointIdxStart <= closestRoutePointIdx) {
          if (this.lastRouteData.Turns[i].TypeIndex == 103 || this.lastRouteData.Turns[i].TypeIndex == 98 ||
            this.lastRouteData.Turns[i].TypeIndex == 106
          ) {
            reachedWaypoints++;
          }
          i++;
        }

        return reachedWaypoints + 1;
      }
      // charging point is further than 30km for every route point
      else {
        return this.inputParamsService.getWaypointsParams().length;
      }
    }
    else {
      return this.inputParamsService.getWaypointsParams().length;
    }
  }

  private redrawChargingStationPopup(chargingStationPopup: ChargingStationDetails): void {
    let chargerPlannedRouteOccurence: number = 0;
    if (this.lastRouteData) {
      const routeChargers: RoutePlanElement[] = this.lastRouteData.RoutePlan.filter((routePlanEl) => {
        return routePlanEl.Type == RoutePlanType.Charger
      });
      chargerPlannedRouteOccurence = routeChargers.filter((routeCharger) => { routeCharger.OperatorId == chargingStationPopup.id }).length;
    }
    this.chargingStationLayer.clearLayers();
    const favoriteCharger = this.settingsService.getContainsFavoriteChargingStationId(chargingStationPopup.id);

    if (chargerPlannedRouteOccurence == 0) {
      /** mobile vertically aligned popup */
      if (device.mobile()) {
        if (favoriteCharger) {
          this.drawChargingStationPopup(PopupAlign.Top, 0, 24, chargingStationPopup, true, true, false, true);
        }
        else {
          this.drawChargingStationPopup(PopupAlign.Top, 0, 20, chargingStationPopup, true, true, false, true);
        }
      }
      /** desktop horizontally aligned popup */
      else {
        const chargingStationsPixelPoint = this.canvasLayer.map.latLngToContainerPoint(new LatLng(chargingStationPopup.lat, chargingStationPopup.lon));

        let widthAnchor;
        if (favoriteCharger) {
          widthAnchor = this.map.getSize().x / 2 > chargingStationsPixelPoint.x ? -23 : 23;
        }
        else {
          widthAnchor = this.map.getSize().x / 2 > chargingStationsPixelPoint.x ? -20 : 20;
        }

        let popupAlign = this.map.getSize().x / 2 > chargingStationsPixelPoint.x ? PopupAlign.Right : PopupAlign.Left;
        this.drawChargingStationPopup(popupAlign, widthAnchor, 0, chargingStationPopup, true, true, false, false);
      }
    }
    else if (chargerPlannedRouteOccurence == 1) {
      /** vertically aligned popup above planned route charger */
      this.drawChargingStationPopup(PopupAlign.Top, 0, 80, chargingStationPopup, true, false, false, true);
    }
    else {
      /** vertically aligned popup above planned route multiple stop charger */
      this.drawChargingStationPopup(PopupAlign.Top, 0, 61 + (19 * chargerPlannedRouteOccurence), chargingStationPopup, true, false, false, true);
    }
  }

  ngOnDestroy(): void {
    // remove map when component destroyed
    this.canvasLayer.off();
    this.canvasLayer.delegate(null);
    // settimeout needed to prevent console error
    setTimeout(() => {
      this.map.removeLayer(this.canvasLayer);
      this.canvasLayer = null;
      this.map.off();
      this.map.remove();
    });

    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];

    this.chargerPopupSubscriptions.forEach(element => {
      element.unsubscribe();
    });
  }
}