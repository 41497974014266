import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Observer, BehaviorSubject, forkJoin } from 'rxjs';
import { InputParamsService } from './input-params.service';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from './utils.service';
import { environment } from 'src/environments/environment';
import { WebviewService } from './webview.service';
import { SettingsService } from './settings.service';
import device from 'current-device';
import { ConsoleLoggerService } from './console-logger.service';
import { timeout } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { HistoryElement } from '../models/history';
import { ECar } from '../models/ecar';
import { ECarType } from '../models/ecar-type';
import { User } from '../models/user';
import { UserProfile } from '../models/user-profile';
import { TripPoint } from '../models/trip';
const API_URL = environment.API_URL + 'api/';

export const enum SubscriptionType {
  Free = "free",
  ProMonthly = "evnavigation.pro.monthly",
  ProYearly = "evnavigation.pro.yearly",
  ProplusMonthly = "evnavigation.proplus.monthly",
  ProplusYearly = "evnavigation.proplus.yearly"
}

@Injectable({
  providedIn: 'root'
})

export class AccountService {

  private isAuthorized: boolean = false;
  public ObservableIsAuthorized: BehaviorSubject<boolean>;
  public ObservableUser: BehaviorSubject<User>;
  public ObservableUserVehicles: BehaviorSubject<ECar[]>;
  private selectedUserCar: ECar;
  public ObservableSelectedUserCar: BehaviorSubject<ECar>;
  public ObservableOpenCarSelectorDialog: BehaviorSubject<boolean>;
  public ObservableVehicleLoader: BehaviorSubject<boolean>;
  public ObservableOpenEditProfileDialog: BehaviorSubject<boolean>;
  public ObservableSocialLoginResponse: BehaviorSubject<{ AuthResponse: boolean, ErrorMessage: boolean }>;
  public ObservableSubscriptionType: BehaviorSubject<SubscriptionType>;
  private historyLastSelectedVehicle: ECar = null;
  private subscriptionType: SubscriptionType = SubscriptionType.Free;

  public accountInit: boolean = false;
  private token: string = null;
  public user: User;
  private ECars: ECarType[];
  public social: boolean = false;
  private provider: string = "";

  constructor(private http: HttpClient, private inputParamsService: InputParamsService,
    private cookieService: CookieService, private matDialog: MatDialog, public webviewService: WebviewService,
    private utilsService: UtilsService, private settingsService: SettingsService,
    private consoleLoggerService: ConsoleLoggerService, private languageService: LanguageService) {
    this.user = new User();
    this.user.Profile = new UserProfile();
    this.ObservableIsAuthorized = new BehaviorSubject<boolean>(null);
    this.ObservableUser = new BehaviorSubject<User>(null);
    this.ObservableUserVehicles = new BehaviorSubject<ECar[]>(null);
    this.ObservableSelectedUserCar = new BehaviorSubject<ECar>(null);
    this.ObservableOpenCarSelectorDialog = new BehaviorSubject<boolean>(null);
    this.ObservableVehicleLoader = new BehaviorSubject<boolean>(null);
    this.ObservableOpenEditProfileDialog = new BehaviorSubject<boolean>(null);
    this.ObservableSocialLoginResponse = new BehaviorSubject<{ AuthResponse: boolean, ErrorMessage: boolean }>(null);
    this.ObservableSubscriptionType = new BehaviorSubject<SubscriptionType>(this.subscriptionType);

    this.webviewService.AddAppleSignInCallback((isSucces: boolean, userId: string, accessToken: string) => { this.handleAppleSignInResponse(isSucces, userId, accessToken); });

    this.inputParamsService.ObservableBatteryChanged.subscribe((resp) => {
      if (this.isAuthorized && resp) {
        this.setVehicleBattery(resp).subscribe();
      }
      if (!this.isAuthorized && resp) {
        localStorage.setItem('batteryLevel', resp.toString());
      }
    });
  }

  public initGoogleLogin(): void {
    window['google'].accounts.id.initialize({
      client_id: '27060447138-oun6fqos4lgeas6okc07f0d10f9vns69.apps.googleusercontent.com',
      cookiepolicy: 'single_host_origin',
      scope: 'profile email',
      callback: (resp) => {
        this.handleGoogleSignInResponse(true, resp.clientId, resp.credential);
      }
    });
  }

  // AUTHORIZATION

  public getIsAuthorized(): boolean {
    return this.isAuthorized;
  }

  public setIsAuthorized(isAuthorized: boolean): void {
    this.isAuthorized = isAuthorized;
    this.settingsService.setIsAuthorized(isAuthorized);
    this.ObservableIsAuthorized.next(isAuthorized);
  }

  // bearer header for authorization
  private getHeader(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      })
    }
  }

  // REGISTRATION

  // checking email address availability
  public isExist(emailAddress: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.post(API_URL + "user/exist", { "email": emailAddress })
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  // checking the country of your location
  public getCountry(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.get(API_URL + "user/country", {}).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
      }, (error) => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // registering a new user
  public registerUser(profile: UserProfile, password: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const formData = new FormData();
      formData.append("first_name", profile.FirstName);
      formData.append("last_name", profile.LastName);
      formData.append("email", profile.Email);
      formData.append("password", password);
      formData.append("c_password", password);
      formData.append("country", profile.Country);
      formData.append("phone_number", profile.Phone.toString());
      formData.append("newsletter", profile.Newsletter ? "1" : "0");
      formData.append("language", this.settingsService.getLanguage());
      if (profile.Avatar) {
        formData.append("avatar", profile.Avatar);
      }
      this.http.post(API_URL + "register", formData)
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
        }, (error) => {
          if (error.error && error.error.error && error.error.error.avatar) {
            observer.error({ failed: { error: error.error.error.avatar } });
          }
          else {
            observer.error({ failed: { error: this.languageService.languageJSON.Global_SomethingWentWrong } });
          }
          observer.complete();
        });
    });
  }

  // email verification for activation profile
  public verifyEmail(urlParam: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.get(urlParam)
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          this.setIsAuthorized(true);
          this.token = resp.success.token;
          this.cookieService.set("evt", this.token, 1, "/");
          this.webviewService.SetAccessToken(this.token, 1);
          observer.next(resp);
          observer.complete();

        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  // send a new email with activation url
  public resendActivationEmail(emailAddress: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.post(API_URL + "resend", { 'email': emailAddress })
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error({ failed: { error: this.languageService.languageJSON.Global_SomethingWentWrong } });
          observer.complete();
        });
    });
  }

  // SOCIAL REGISTRATION/LOGIN

  // login with social media account
  public authenticateSocial(provider: string, providerId: string, accessToken: string, native?: boolean): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const formData = new FormData();
      formData.append("provider", provider);
      if (provider === "apple") {
        let user = JSON.parse(providerId);
        formData.append("provider_id", user.userID.toString());
        formData.append("user", JSON.stringify({ "name": { "firstName": user.name.firstname, "lastName": user.name.lastname }, "email": user.email }));
      }
      else {
        formData.append("provider_id", providerId);
      }
      formData.append("access_token", accessToken);
      formData.append("native", native ? native.toString() : null);

      const url = provider === "google" && !this.webviewService.IsGPSTWebview() ? "register_external_gsi" : "register_external";

      this.http.post(API_URL + url, formData)
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          if (resp.success) {
            this.initSocialProfile(resp, provider).subscribe((respInit) => {
              observer.next(resp);
              observer.complete();
            }, (error) => {
              observer.error(error);
              observer.complete();
            });
          }
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  // set authorization, store token in cookies
  public initSocialProfile(resp: any, provider: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.provider = provider;
      this.setIsAuthorized(true);
      this.token = resp.success.token;
      this.cookieService.set("evt", this.token + "social", 14, "/");
      this.webviewService.SetAccessToken(this.token, 14);
      this.cookieService.set("accountProvider", provider);
      this.social = true;

      this.loadUserProfile().subscribe(() => {
        if (resp.success.error || (!this.user.Profile.FirstName || !this.user.Profile.LastName)) {
          this.ObservableOpenEditProfileDialog.next(true);
        }
        // wait user profile/settings/subscriptions/vehicles http calls
        this.initUserData(false, true, true).subscribe(() => {
          observer.next(resp);
          observer.complete();
        },
          (error) => {
            observer.error(error);
            observer.complete();
          });
      },
        (error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  // LOGIN, INITIALIZATION

  // user login
  public login(login: { email: string, password: string, rememberMe: boolean }): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const formData = new FormData();
      formData.append("email", login.email);
      formData.append("password", login.password);
      this.http.post(API_URL + "login", formData)
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          if (resp.success) {
            this.setIsAuthorized(true);
            this.token = resp.success.token;
            if (login.rememberMe == true) {
              this.cookieService.set("evt", this.token, 365, "/");
              this.webviewService.SetAccessToken(this.token, 365);
            }
            else {
              this.cookieService.set("evt", this.token, 1, "/");
              this.webviewService.SetAccessToken(this.token, 1);
            }

            this.initUserData(true, true, true).subscribe(() => {
              observer.next(resp);
              observer.complete();
            }, (error) => {
              observer.error(error);
              observer.complete();
            });
          }

          if (resp.failed && resp.failed == "USER_ERR_205") {
            observer.next(resp);
            observer.complete();
          }
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public initAccount(): boolean {
    let evtoken = this.cookieService.get("evt");
    if (evtoken && evtoken != "null") {
      if (evtoken.slice(-6) == "social") {
        this.token = evtoken.slice(0, -6);
        this.social = true;
      }
      else {
        this.token = evtoken;
      }
      this.setIsAuthorized(true);
    }

    const accountProvider = this.cookieService.get("accountProvider");
    if (accountProvider && accountProvider != "null") {
      this.provider = accountProvider;
    }

    return this.isAuthorized;
  }

  public initUserData(loadUserProfile: boolean, getUserSettings: boolean, getUserVehicles: boolean): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const accountHttpCalls = [];

      if (loadUserProfile) {
        accountHttpCalls.push(this.loadUserProfile());
      }
      if (getUserSettings) {
        accountHttpCalls.push(this.getUserSettings());
      }
      if (getUserVehicles) {
        accountHttpCalls.push(this.getUserVehicles());
      }

      // wait user profile/settings/subscriptions/vehicles http calls
      forkJoin(accountHttpCalls).subscribe((resp) => {
        observer.next(resp);
        observer.complete();
      }, (error) => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // get user profile data
  public loadUserProfile(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.get(API_URL + "user", this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          if (resp.email) {
            this.setUserData(resp);
          }
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  private setUserData(resp: any): void {
    this.user.Profile.Email = resp.email;
    this.user.Profile.FirstName = resp.first_name;
    this.user.Profile.LastName = resp.last_name;
    this.user.Profile.Country = resp.country;
    this.user.Profile.Phone = resp.phone_number;
    this.user.Profile.Newsletter = resp.newsletter;
    this.user.Profile.Avatar = resp.avatar;
    this.user.Profile.Id = resp.id;
    if (this.webviewService.IsGPSTWebview()) {
      this.webviewService.SetUserId(resp.id);
    }
    if (this.user.Profile.Avatar == "users/default.png") {
      this.user.Profile.Avatar = null;
    }
    if (resp.addresses != null) {
      this.settingsService.setAddresses(this.settingsService.addressesJSONtoAddresses(JSON.parse(resp.addresses)));
    }

    if (resp.stations) {
      const stations = JSON.parse(resp.stations);
      if (stations && stations.disabled) {
        this.settingsService.setDisabledChargingStationIds(stations.disabled);
      }
      else {
        this.settingsService.setDisabledChargingStationIds([]);
      }
      if (stations && stations.favorite) {
        this.settingsService.setFavoriteChargingStationIds(stations.favorite);
      }
      else {
        this.settingsService.setFavoriteChargingStationIds([]);
      }
    }
    else {
      this.settingsService.setDisabledChargingStationIds([]);
      this.settingsService.setFavoriteChargingStationIds([]);
    }

    if (resp.operators) {
      const operators = JSON.parse(resp.operators);
      if (operators && operators.disabled) {
        this.settingsService.setDisabledChargingOperatorIds(operators.disabled.map(Number));
      }
      else {
        this.settingsService.setDisabledChargingOperatorIds([]);
      }
      if (operators && operators.favorite) {
        this.settingsService.setFavoriteChargingOperatorIds(operators.favorite.map(Number));
      }
      else {
        this.settingsService.setFavoriteChargingOperatorIds([]);
      }
    }
    else {
      this.settingsService.setDisabledChargingOperatorIds([]);
      this.settingsService.setFavoriteChargingOperatorIds([]);
    }

    if (resp.disabledChargingStation != null) {
      this.settingsService.setDisabledChargingStationIds(JSON.parse(resp.disabledChargingStation));
    }

    this.ObservableUser.next(this.user);
  }

  public initAllVehicles(allVehicles: ECarType[]): void {
    this.ECars = allVehicles;
    // init user
    if (this.user.Ecars != null && this.user.Ecars != undefined && this.user.Ecars.length > 0) {
      for (let userCarIdx = 0; userCarIdx < this.user.Ecars.length; ++userCarIdx) {
        const userEcar = this.user.Ecars[userCarIdx];
        for (let i = 0; i < this.ECars.length; ++i) {
          if (this.ECars[i].Id == userEcar.userVehicleID) {
            userEcar.ECarType = { ...this.ECars[i] };
            break;
          }
        }
      }
    }
    if (this.accountInit == false) {
      this.initAccount();
      this.accountInit = true;
    }
  }

  // getting user vehicle list
  public getUserVehicles(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.ObservableVehicleLoader.next(true);
      this.http.get(API_URL + "user/vehicles", this.getHeader()).subscribe((resp: any) => {
        this.ObservableVehicleLoader.next(false);
        if (resp.success && resp.success.length > 0) {
          this.initUserVehicles(resp.success);
        }
        else {
          if (!document.URL.includes("verify-email")) {
            this.ObservableOpenCarSelectorDialog.next(true);
          }
        }
        observer.next(resp);
        observer.complete();
      }, (error) => {
        this.checkErrorStatus(error);
        observer.error(error);
        observer.complete();
      });
    });
  }

  // processing user vehicles data
  private initUserVehicles(resp: any): void {
    if (resp) {
      this.user.Ecars = [];
      for (let carIdx = 0; carIdx < resp.length; carIdx++) {
        const carData = resp[carIdx];
        const userEcar = new ECar();
        userEcar.userVehicleID = carData.id;
        userEcar.name = (carData.name ? carData.name : "");
        userEcar.ECarType = null;
        userEcar.selected = ((carData.selected != undefined) && (carData.selected != undefined) && (parseInt(carData.selected) === 1));
        if (carData.settings != undefined) {
          userEcar.settings = JSON.parse(carData.settings);
        }
        if (this.ECars != null && this.ECars != undefined && this.ECars.length > 0) {
          for (let i = 0; i < this.ECars.length; ++i) {
            if (this.ECars[i].Id == carData.vehicle_id) {
              userEcar.ECarType = { ... this.ECars[i] };
              break;
            }
          }
        }
        if (userEcar.ECarType) {
          userEcar.ECarType.ChargerTypes = JSON.parse(carData.charger_types);
        }

        this.user.Ecars.push(userEcar);

        if (userEcar.selected === true && userEcar.ECarType != null && userEcar.ECarType.Icon) {
          this.selectedUserCar = userEcar;
          this.ObservableSelectedUserCar.next(userEcar);
        }
      }
      if (this.ECars != null && this.ECars != undefined && this.ECars.length > 0) {
        this.ObservableUserVehicles.next(this.user.Ecars);
      }
    }
  }

  // GETTING, SETTING USER DATA

  public getUserProfile(): User {
    return this.user;
  }

  // changing user profile
  public setUser(profile: UserProfile): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const formData = new FormData();
      formData.append("first_name", profile.FirstName);
      formData.append("last_name", profile.LastName);
      formData.append("country", profile.Country);
      formData.append("phone_number", profile.Phone.toString());
      formData.append("newsletter", profile.Newsletter ? "1" : "0");
      if (profile.Avatar && typeof (profile.Avatar) == "object") {
        formData.append("avatar", profile.Avatar);
      }
      if (profile.Avatar == null) {
        formData.append("avatar", "DELETE");
      }
      this.http.post(API_URL + "user/profile", formData, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          // setting changed user profile values
          if (resp && resp.success) {
            this.user.Profile.FirstName = profile.FirstName;
            this.user.Profile.LastName = profile.LastName;
            this.user.Profile.Country = profile.Country;
            this.user.Profile.Phone = profile.Phone;
            this.user.Profile.Newsletter = profile.Newsletter;
            this.user.Profile.Avatar = resp.success.avatar;
            if (this.user.Profile.Avatar == "users/default.png") {
              this.user.Profile.Avatar = null;
            }
          }
          observer.next(resp);
          this.setUserData(resp.success);
          observer.complete();
        }, (error) => {
          if (error.status && error.status == 401 &&
            error.error && error.error.error && error.error.error.avatar) {
            //error msg
          }
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  public getUserAvatar(): string {
    return this.user.Profile.Avatar;
  }

  public setUserSubscription(subscriptionType: SubscriptionType){
    this.subscriptionType = subscriptionType;
    this.ObservableSubscriptionType.next(subscriptionType);
  }

  public getUserSubscription(): SubscriptionType{
    return this.subscriptionType;
  }

  // adding a new vehicle to user account
  public addVehicle(vehicleId: number, vehicleName: string, chargerTypes: number[], batteryStateOfHealth: number, fastChargeLimit: number): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const settings = {
        "batteryLevel": 73, "weight": 2, "tirePressure": 2.3, "userBehavior": 50, "speedLimiter": 100,
        "batterySafetyLimitRha": 50, "batterySafetyLimitRoute": 15, "minBatteryAtDestination": 10,
        "batteryStateOfHealth": batteryStateOfHealth, "fastChargeLimit": fastChargeLimit, "tripRecording": 1
      }
      this.http.post(API_URL + "user/vehicles/add", { vehicle_id: vehicleId, name: vehicleName, charger_types: "[" + chargerTypes.toString() + "]", settings: JSON.stringify(settings) }, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          if (resp.success && this.user.getSelectedCar() == undefined) {
            this.selectVehicle(resp.success.id).subscribe();
          }
          this.getUserVehicles().subscribe(() => {
            observer.next(resp);
            observer.complete();
          });
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  // adding default vehicle to user account
  public addDefaultVehicleToUser(): void {
    let defaultCar: ECarType;
    let defCarId: number = this.utilsService.defaultCarIndex;
    for (let i = 0; i < this.ECars.length; ++i) {
      if (this.ECars[i].Id == defCarId) {
        defaultCar = this.ECars[i];
        break;
      }
    }
    this.addVehicle(defaultCar.Id, defaultCar.Name, defaultCar.ChargerTypes, 100, 90).subscribe();
  }

  // change active vehicle
  public selectVehicle(vehicleId: number): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.post(API_URL + "user/vehicles/select", { user_vehicle_id: vehicleId }, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          this.getUserVehicles().subscribe(() => {
            observer.next(resp);
            observer.complete();
          });
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  // change user vehicle details
  public editVehicle(userVehicleId: number, vehicleId: number, vehicleName: string, chargerTypes: number[], settings: string, reloadVehicles: boolean): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const postData = { user_vehicle_id: userVehicleId };
      if (vehicleName != null) { postData['name'] = vehicleName; };
      if (chargerTypes) { postData['charger_types'] = "[" + chargerTypes.toString() + "]"; }
      if (vehicleId) { postData['vehicle_id'] = vehicleId; }
      if (settings) { postData['settings'] = settings; }
      this.http.post(API_URL + "user/vehicles/modify", postData,
        this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          if (reloadVehicles) {
            this.getUserVehicles().subscribe();
          }
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  public setVehicleBattery(batteryLevel: number): Observable<any> {
    const userCar = this.user.getSelectedUserCar();
    userCar.settings.batteryLevel = batteryLevel;
    return this.editVehicle(userCar.userVehicleID, null, null, null, JSON.stringify(userCar.settings), false);
  }

  // remove a vehicle from the user account
  public removeVehicle(vehicleId): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.post(API_URL + "user/vehicles/remove", { user_vehicle_id: vehicleId }, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          this.getUserVehicles().subscribe();
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  // modify user password
  public changePassword(emailAddress: string, currentPassword: string, newPassword: string, cNewPassword: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const formData = new FormData();
      formData.append("email", emailAddress);
      formData.append("current_password", currentPassword);
      formData.append("new_password", newPassword);
      formData.append("new_confirm_password", cNewPassword);
      this.http.post(API_URL + "user/changepassword", formData, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  // request resetting password
  public getForgottenPassword(emailAddress: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.post(API_URL + "reset/request", { email: emailAddress })
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  // load user settings
  public getUserSettings(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.get(API_URL + "user/settings", this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          if (resp.success) {
            if (resp.success.mapsettings) { resp.success.mapsettings = JSON.parse(resp.success.mapsettings) };
            if (resp.success.routing) { resp.success.routing = JSON.parse(resp.success.routing) };
            if (resp.success.unit) { resp.success.unit = JSON.parse(resp.success.unit) };
            if (resp.success.voiceguidance) { resp.success.voiceguidance = JSON.parse(resp.success.voiceguidance) };
            if (resp.success.voiceInputTutorialDone) { resp.success.voiceInputTutorialDone = JSON.parse(resp.success.voiceInputTutorialDone) };
            if (resp.success.tracking) { resp.success.tracking = JSON.parse(resp.success.tracking); }

            if (resp.success.routing != undefined && resp.success.routing != null) {
              const routingSettings = this.settingsService.routingJSONtoRoutingSettings(resp.success.routing);

              if (!(this.settingsService.getRouting().Ferries == routingSettings.Ferries
                && this.settingsService.getRouting().Highways == routingSettings.Highways
                && this.settingsService.getRouting().TollRoads == routingSettings.TollRoads)) {
                this.settingsService.setRounting(routingSettings);
                this.inputParamsService.paramsUpdate();
              }
            }

            if (resp.success.voiceguidance != undefined && resp.success.voiceguidance != null) {
              this.settingsService.setVoiceGuidence(this.settingsService.voiceGuidenceJSONtoVoiceGuidenceSettings(resp.success.voiceguidance));
            }

            if (resp.success.voiceInputTutorialDone != undefined && resp.success.voiceInputTutorialDone != null) {
              this.settingsService.setVoiceInputTutorialDone(resp.success.voiceInputTutorialDone);
            }

            if (resp.success.unit != undefined && resp.success.unit != null) {
              this.settingsService.setUnit(this.settingsService.unitJSONtoUnitSettings(resp.success.unit));
            }

            if (resp.success.mapsettings != undefined && resp.success.mapsettings != null) {
              const mapsettings = resp.success.mapsettings;
              if (mapsettings.skin != undefined && mapsettings.skin != null) {
                this.settingsService.setMap(this.settingsService.mapJSONtoMapSettings(resp.success.mapsettings));
              }
            }

            if (resp.success.tracking != undefined && resp.success.tracking != null) {
              this.settingsService.setTracking(resp.success.tracking);
            }

            if (resp.success.language != undefined && resp.success.language != null) {
              this.settingsService.setLanguage(resp.success.language);
            }
          }

          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  setUserSettings(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const formData = new FormData();
      const unit = this.settingsService.getUnit();
      const routing = this.settingsService.getRouting();
      const voiceguidance = this.settingsService.getVoiceGuidence();
      const mapsettings = this.settingsService.getMap();
      const voiceInputTutorialDone = this.settingsService.getVoiceInputTutorialDone();
      const tracking = this.settingsService.getTracking();
      const language = this.settingsService.getLanguage();

      if (unit != undefined && unit != null) { formData.append("unit", JSON.stringify(unit).toLowerCase()); }
      if (routing != undefined && routing != null) { formData.append("routing", JSON.stringify(routing).toLowerCase()); }
      if (voiceguidance != undefined && voiceguidance != null) { formData.append("voiceguidance", JSON.stringify(voiceguidance).toLowerCase()); }
      if (mapsettings != undefined && mapsettings != null) { formData.append("mapsettings", JSON.stringify(mapsettings).toLowerCase()); }
      if (voiceInputTutorialDone != undefined && voiceInputTutorialDone != null) { formData.append("voiceInputTutorialDone", JSON.stringify(voiceInputTutorialDone).toLowerCase()); }
      if (tracking != undefined && tracking != null) { formData.append("tracking", JSON.stringify(tracking).toLowerCase()); }
      if (language) { formData.append("language", language); };

      this.http.post(API_URL + "user/settings", formData, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          /*if (resp.success.mapsettings) { resp.success.mapsettings = JSON.parse(resp.success.mapsettings) };
          if (resp.success.routing) { resp.success.routing = JSON.parse(resp.success.routing) };
          if (resp.success.unit) { resp.success.unit = JSON.parse(resp.success.unit) };
          if (resp.success.voiceguidance) { resp.success.voiceguidance = JSON.parse(resp.success.voiceguidance) };
          if (resp.success.voiceInputTutorialDone) { resp.success.voiceInputTutorialDone = JSON.parse(resp.success.voiceInputTutorialDone) };
          if (resp.success.tracking) { resp.success.tracking = JSON.parse(resp.success.tracking); }*/
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  // set home/work address
  public setUserAddresses(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const formData = new FormData();
      formData.append("addresses", JSON.stringify(this.settingsService.getAddresses()));
      this.http.post(API_URL + "user/address", formData, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  public setDisabledChargingStations(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const formData = new FormData();
      formData.append("ids", JSON.stringify(this.settingsService.getDisabledChargingStationIds()));
      this.http.post(API_URL + "user/stations/disable", formData, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  public setFavoriteChargingStations(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const formData = new FormData();
      formData.append("ids", JSON.stringify(this.settingsService.getFavoriteChargingStationIds()));
      this.http.post(API_URL + "user/stations/favorite", formData, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  public setDisabledChargingOperators(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const formData = new FormData();
      formData.append("ids", JSON.stringify(this.settingsService.getDisabledChargingOperatorIds()));
      this.http.post(API_URL + "user/operator/disable", formData, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  public setFavoriteChargingOperators(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const formData = new FormData();
      formData.append("ids", JSON.stringify(this.settingsService.getFavoriteChargingOperatorIds()));
      this.http.post(API_URL + "user/operator/favorite", formData, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error);
          observer.error(error);
          observer.complete();
        });
    });
  }

  public deleteUser(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.post(API_URL + "user/delete", {}, this.getHeader())
        .pipe(
          timeout(60000)
        )
        .subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
        }, (error) => {
          this.checkErrorStatus(error)
          observer.error(error);
          observer.complete();
        });
    });
  }

  // LOGOUT

  // log out 401 error status
  private checkErrorStatus(error): void {
    if (error.status && error.status == 401) {
      this.logout();
    }
  }

  public logout(): void {
    switch (this.provider) {
      case "google":
        this.signOutGoogle();
        break;
      case "facebook":
        this.signOutFacebook();
        break;
    }
    this.provider = "";
    this.settingsService.initSettings();
    this.setIsAuthorized(false);
    this.user = new User();
    this.user.Profile = new UserProfile();
    this.token = null;
    this.social = false;
    this.cookieService.delete("evt");
    this.webviewService.ClearAccessToken();
  }

  public getHistory(carId: number): Observable<HistoryElement[]> {
    return new Observable((observer: Observer<HistoryElement[]>) => {
      // getting new history
      if (this.user.HistoryList[carId.toString()] == undefined) {
        this.http.post(API_URL + `evnavi/getActivities/${carId}`, null, this.getHeader()).subscribe((resp: any) => {
          resp = resp.map(el => {
            return {
              id: el.id,
              startLocation: el.from_loc,
              targetLocation: el.end_loc,
              startTime: el.from ? el.from.replace(/\s/, 'T') : el.from,
              distance: Math.round(el.distance * 10) / 10,
              totalTime: JSON.parse(el.time_duration),
              avgConsumption: (Math.round(el.averageConsumption * 10) / 10),
              totalConsumption: (Math.round(el.consumption * 10) / 10)
            }
          });

          this.user.HistoryList[carId.toString()] = resp;
          observer.next(resp);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
      }
      // reload previously loaded history
      else {
        observer.next(this.user.HistoryList[carId.toString()]);
        observer.complete();
      }
    });
  }

  public getHistoryLastSelectedVehicle(): ECar {
    return this.historyLastSelectedVehicle;
  }

  public setHistoryLastSelectedVehicle(historyLastSelectedVehicle: ECar): void {
    this.historyLastSelectedVehicle = historyLastSelectedVehicle;
  }

  public getTrack(logId: number): Observable<TripPoint[]> {
    return new Observable((observer: Observer<TripPoint[]>) => {
      // getting new track
      if (this.user.TipList[logId.toString()] == undefined) {
        this.http.post(API_URL + `evnavi/readfiles/${logId}`, null, this.getHeader()).subscribe((resp: any) => {
          //resp = JSON.parse(resp);
          this.user.TipList[logId] = resp;
          observer.next(resp);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
      }
      // reload previously loaded track
      else {
        observer.next(this.user.TipList[logId.toString()]);
        observer.complete();
      }
    });
  }

  public uploadLog(userVehicleId, data): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.post(API_URL + `evnavi/log/${userVehicleId}`, { geojson: data }, this.getHeader()).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
      }, (error) => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public deleteLogById(carId: number, logId: number): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.post(API_URL + `evnavi/deletelogbyid/${logId}`, null,
        { headers: this.getHeader().headers, responseType: "text" }).subscribe((resp: any) => {
          this.user.HistoryList[carId.toString()] = undefined;
          observer.next(resp);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public deleteLogByVehicle(carId: number): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.http.post(API_URL + `evnavi/deletelogbyvehicle/${carId}`, null,
        { headers: this.getHeader().headers, responseType: "text" }).subscribe((resp: any) => {
          this.user.HistoryList[carId.toString()] = undefined;
          observer.next(resp);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  // SOCIAL SIGN IN

  private handleFacebookSignInResponse(isSucces: boolean, userId: string, accessToken: string): void {
    if (isSucces) {
      //response.authResponse.accessToken
      this.authenticateSocial("facebook", userId, accessToken).subscribe((resp) => {
        this.ObservableSocialLoginResponse.next({ AuthResponse: resp.success, ErrorMessage: resp.failed });
      });
    } else {
    }
  }

  public handleGoogleSignInResponse(isSucces: boolean, userId: string, accessToken: string): void {
    if (isSucces) {
      this.authenticateSocial("google", userId, accessToken).subscribe((resp) => {
        this.ObservableSocialLoginResponse.next({ AuthResponse: resp.success, ErrorMessage: resp.failed });
      });
    }
  }

  private handleAppleSignInResponse(isSuccess: boolean, userId: string, accessToken: string): void {
    if (isSuccess) {
      this.authenticateSocial("apple", userId, accessToken, true).subscribe((resp) => {
        this.ObservableSocialLoginResponse.next({ AuthResponse: resp.success, ErrorMessage: resp.failed });
      });
    }
  }

  /* sign in wih facebook */
  public signInWithFacebook(): void {
    if (this.webviewService.IsGPSTWebview()) {
      this.webviewService.AddFacebookSignInCallback((isSucces: boolean, userId: string, accessToken: string) => { this.handleFacebookSignInResponse(isSucces, userId, accessToken); });
      this.webviewService.FacebookSignIn();
    }
    else {
      window['FB'].login((response) => {
        if (response.authResponse) {
          this.handleFacebookSignInResponse(response.authResponse, response.authResponse.userID, response.authResponse.accessToken);
        }
      }, { scope: 'email' });
    }
  }

  private signOutFacebook(): void {
    if (this.webviewService.IsGPSTWebview()) {
      this.webviewService.FacebookLogout();
    }
    else {
      if (window['FB']) {
        window['FB'].getLoginStatus(function (response) {
          if (response && response.status === 'connected') {
            window['FB'].logout(function (response) {
            });
          }
        });
      }
    }
  }

  /* apple login */
  public signInWithApple(): void {
    if (this.webviewService.IsGPSTWebview() && device.ios()) {
      this.webviewService.AppleSignIn();
    }
    else {
      window["AppleID"].auth.init({
        clientId: environment.APPLE_CLIENT_ID,
        redirectURI: environment.APPLE_REDIRECT_URI,
        scope: "name email"
      });

      window["AppleID"].auth.signIn();
    }
  }

  public signInWithGoogle(): void {
    if (this.webviewService.IsGPSTWebview()) {
      this.webviewService.AddGoogleSignInCallback((isSucces: boolean, userId: string, accessToken: string) => { this.handleGoogleSignInResponse(isSucces, userId, accessToken); });
      this.webviewService.GoogleSignIn();
    }
  }

  private signOutGoogle(): void {
    if (this.webviewService.IsGPSTWebview()) {
      this.webviewService.GoogleLogOut();
    }
  }
}